import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ActiveSeasons } from '../interfaces/Season';
import { ResourceService } from './resource.service';
import { SalaryRangeItem, Season } from 'libs/interfaces/src';
import { Injectable } from '@angular/core';
import { HttpHelperService } from './http-helper.service';

@Injectable({
    providedIn: 'root'
})
export class SeasonService extends ResourceService<Season> {
    constructor(protected override httpClient: HttpClient, protected override httpHelperService: HttpHelperService) {
        super(httpClient, httpHelperService, 'seasons');
    }

    public getActive(): Observable<ActiveSeasons> {
        return this.httpClient
            .get<ActiveSeasons>(`${this.baseUrl}/seasons/active`)
            .pipe(this.httpHelperService.handleError(`Could not fetch active seasons`));
    }

    public getSalaryRangeItems(startDate: Date, endDate: Date): Observable<SalaryRangeItem[]> {
        const params = {
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString()
        };

        return this.httpClient
            .get<SalaryRangeItem[]>(`${this.baseUrl}/seasons/salary-range-items`, { params })
            .pipe(this.httpHelperService.handleError(`Could not fetch salary range items`));
    }
}
