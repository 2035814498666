import { Component, HostListener, OnDestroy, OnInit, Signal } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ScreenState } from './shared/state/screen.state';
import { select, Store } from '@ngxs/store';
import { HideScreenTooSmall, ShowScreenTooSmall } from './shared/state/screen.actions';
import { DialogService } from '@siemens/component-lib';
import { HelpDeskDialogComponent } from './dialogs/help-desk-dialog/help-desk-dialog.component';
import { filter, map, merge, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    title = 'frontend-sag';
    protected isScreenTooSmall: Signal<boolean> = select(ScreenState.isScreenTooSmall);
    protected wasScrolled = false;
    private destroy$ = new Subject<void>();

    constructor(
        private store: Store,
        private translate: TranslateService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private titleService: Title,
        private dialogService: DialogService
    ) {}

    ngOnInit(): void {
        this.routeToRedirect();
        this.translate.setDefaultLang('de');
        this.translate.use('de');
        this.onResize();
        this.translatePageTitle();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private routeToRedirect(): void {
        const url = window.localStorage.getItem('redirectUrl');
        if (url) {
            void this.router.navigateByUrl(url);
            window.localStorage.removeItem('redirectUrl');
        }
    }

    @HostListener('window:resize', ['$event']) onResize(): void {
        const requiredWindowWidth = this.store.selectSnapshot(ScreenState.requiredScreenWidth);
        this.store.dispatch(
            window.innerWidth < requiredWindowWidth ? new ShowScreenTooSmall() : new HideScreenTooSmall()
        );
    }

    // determine if user scrolled to decide if header should have background or be transparent
    @HostListener('window:scroll', [])
    onWindowScroll(): void {
        if (document.querySelector('.cdk-global-scrollblock')) {
            // When displaying dialogs, stop checking for scroll. As the scrolledDistance is always equal to 0
            return;
        }

        const scrolledDistance = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;
        this.wasScrolled = scrolledDistance > 48;
    }

    protected openHelpDeskDialog(): void {
        this.dialogService
            .open(HelpDeskDialogComponent, { width: '550px' })
            .afterClosed.pipe(takeUntil(this.destroy$))
            .subscribe();
    }

    private translatePageTitle(): void {
        merge(this.router.events.pipe(filter(event => event instanceof NavigationEnd)), this.translate.onLangChange)
            .pipe(
                map(() => this.deepestRouteWithTitle()),
                map(route => route.snapshot.title || 'page-titles.home'),
                switchMap(title => this.translate.get(title)),
                tap(translatedTitle => {
                    setTimeout(() => {
                        this.titleService.setTitle(translatedTitle);
                    }, 10);
                }),
                takeUntil(this.destroy$)
            )
            .subscribe();
    }

    private deepestRouteWithTitle(): ActivatedRoute {
        let route = this.activatedRoute;
        while (route.firstChild) {
            route = route.firstChild;
        }
        return route;
    }
}
