export function age(birthDate: Date): number {
    const currentDate = new Date();
    let age = currentDate.getFullYear() - birthDate.getFullYear();

    if (
        birthDate.getMonth() > currentDate.getMonth() ||
        (birthDate.getMonth() === currentDate.getMonth() && birthDate.getDate() > currentDate.getDate())
    ) {
        age--;
    }

    return age;
}
