import { GbrUser } from 'interfaces/src';
import { LanguageCode } from 'interfaces/enums';

export class SetUser {
    static readonly type = '[User] Set User';
    constructor(public readonly payload: { user: GbrUser; isAuthenticated: boolean }) {}
}

export class SetUserLanguage {
    static readonly type = '[User] Set User Language';
    constructor(public readonly payload: { language: LanguageCode }) {}
}
