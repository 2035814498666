import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogRef } from '@siemens/component-lib';
import { SharedModule } from '../../shared/shared.module';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-help-desk-dialog',
    standalone: true,
    imports: [CommonModule, SharedModule],
    templateUrl: './help-desk-dialog.component.html'
})
export class HelpDeskDialogComponent {
    protected readonly translationPath = 'dialogs.help-desk.';
    private readonly faqLinkPath = this.translationPath + 'faqLink';
    private readonly helpDeskLink = this.translationPath + 'helpDeskLink';

    constructor(private dialogRef: DialogRef<void>, private translateService: TranslateService) {}

    protected onClose(): void {
        this.dialogRef.close();
    }
    protected openLinkInNewTab(isFaq: boolean): void {
        const linkToOpen = this.translateService.instant(isFaq ? this.faqLinkPath : this.helpDeskLink);
        window.open(linkToOpen, '_blank');
    }
}
