import { Component } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ButtonModule } from '@siemens/component-lib';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-home',
    standalone: true,
    imports: [CommonModule, ButtonModule, NgOptimizedImage, TranslateModule],
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent {
    constructor(private readonly router: Router) {}

    public scrollToNextSection(): void {
        window.document.querySelector('#home-trail')?.scrollIntoView({ behavior: 'smooth', inline: 'start' });
    }

    public navigateToDashboard(): void {
        this.router.navigate(['/dashboard']);
    }
}
