export class AttachmentOperations {
    public static downloadFileByUrl(url: string, fileName: string): void {
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.click();

        URL.revokeObjectURL(url);
    }

    public static downloadFile(file: File): void {
        const url = URL.createObjectURL(file);
        this.downloadFileByUrl(url, file.name);
    }
}
