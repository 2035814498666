export interface CreateApplication {
    employeeGid: string;
    seasonId: string;
    delegateGid?: string;
    isSingleParent?: boolean;
    spouse?: CreateApplicationSpouse;
    children?: CreateApplicationChild[];
    isDisabled?: boolean;
    isCaregiver?: boolean;
    isOnParentalLeave?: boolean;
    isShiftWorker?: boolean;
    isPartTime?: boolean;
    salaryRangeId?: string;
    hasGivenConsent?: boolean;
    travelStartDate?: Date;
    travelEndDate?: Date;
    emailPrivate?: string;
    confirmationOfDataCorrectness?: boolean;
}

export interface CreateApplicationChild {
    birthDate?: Date;
    isEligible?: boolean;
    isDisabled?: boolean;
}

export interface CreateApplicationSpouse {
    gid?: string;
    firstName?: string;
    lastName?: string;
    isSiemensEmployee?: boolean;
    isDisabled?: boolean;
}

export enum ApplicationStatus {
    DECLINED = 'Declined',
    DRAFT = 'Draft',
    SUBMITTED = 'Submitted',
    APPROVED = 'Approved',
    CANCELED = 'Canceled'
}
