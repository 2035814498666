import { APP_INITIALIZER, ApplicationRef, DoBootstrap, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxsModule } from '@ngxs/store';

import { HttpClient, HttpClientModule } from '@angular/common/http';
import { filter, Observable, tap } from 'rxjs';
import { AuthGmsAngularClientModule } from './auth/auth.module';
import { SharedModule } from './shared/shared.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from './auth/services/auth.service';
import { ReactiveFormsModule } from '@angular/forms';
import { UserState } from './auth/state/user.state';
import { environment } from '../environments/environment';
import { ApplicationsState } from './applications/state/applications.state';
import { ApplicationProcessState } from './dashboard/application-process/state/application-process.state';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
    const fileLoc = encodeURIComponent('customer/');
    return new TranslateHttpLoader(http, `${environment.translationsUrl}/translations/${fileLoc}`, '.json');
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        ReactiveFormsModule,
        SharedModule,
        NgxsModule.forRoot([UserState, ApplicationsState, ApplicationProcessState]),
        NgxsFormPluginModule.forRoot(),
        TranslateModule.forRoot({
            defaultLanguage: 'de',
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        AuthGmsAngularClientModule
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initializeAppFactory,
            deps: [AuthService],
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule implements DoBootstrap {
    constructor(private authService: AuthService) {}

    public ngDoBootstrap(appRef: ApplicationRef): void {
        this.authService.isAuthenticated$
            .pipe(
                filter(auth => !!auth),
                tap(() => appRef.bootstrap(AppComponent))
            )
            .subscribe();
    }
}

function initializeAppFactory(authService: AuthService): () => Observable<boolean> {
    return () =>
        authService.isAuthenticated$.pipe(
            filter(auth => !auth),
            tap(() => authService.login())
        );
}
