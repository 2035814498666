<div class="flex items-center justify-center h-screen bg-[#000028]">
    <div class="max-w-[400px] text-white">
        <div>
            <h1 class="mb-10" [attr.aria-level]="2">
                <strong>{{ titleText }}</strong>
            </h1>
        </div>
        <p [innerHTML]="descriptionText"></p>
    </div>
</div>
