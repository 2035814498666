import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ThemeService } from '../../services/theme.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<void>();
    @Input() wasScrolled: boolean;
    isSidebarOpen = false;

    constructor(private translate: TranslateService, private themeService: ThemeService) {}

    ngOnInit(): void {
        this.setTheme('dark');
    }

    public changeLang(langCode: string): void {
        this.translate.use(langCode).pipe(takeUntil(this.destroy$)).subscribe();
    }

    private setTheme(mode: 'dark' | 'light') {
        this.themeService.setTheme(mode);
        localStorage.setItem('theme', JSON.stringify(mode));
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
