import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UnauthorizedComponent } from 'shared-ui';

const routes: Routes = [{ path: 'unauthorized', component: UnauthorizedComponent }]; // sets up routes constant where you define your routes

@NgModule({
    imports: [RouterModule.forChild(routes), UnauthorizedComponent],
    exports: [RouterModule]
})
export class AuthGmsRoutingModule {}
