import { Application, GbrUser } from 'libs/interfaces/src';
import { UserAttachment } from '../../../shared/interfaces/Attachments';

export class IncrementStepper {
    static readonly type = '[Application Form] Increment Stepper';
}

export class DecrementStepper {
    static readonly type = '[Application Form] Decrement Stepper';
}

export class ResetStepper {
    static readonly type = '[Application Form] Reset Stepper';
}

export class SetStepper {
    static readonly type = '[Application Form] Set Stepper';
    constructor(public step: number) {}
}

export class ResetApplicationForm {
    static readonly type = '[Application Form] Reset Form';
}

export class SubmitApplicationForm {
    static readonly type = '[Application Form] Submit Form';
}

export class GetActiveSeasons {
    static readonly type = '[Application Form] Get Active Seasons';
}

export class GetSalaryRanges {
    static readonly type = '[Application Form] Get Salary Ranges';
    constructor(public payload?: { startDate?: Date | null; endDate?: Date | null }) {}
}

export class AddAttachment {
    static readonly type = '[Application Form] Add Attachment';
    constructor(public attachment: UserAttachment) {}
}

export class RemoveAttachment {
    static readonly type = '[Application Form] Remove Attachment';
    constructor(public index: number) {}
}

export class SaveApplication {
    static readonly type = '[Application Form] Save Application';
}

export class CreateApplication {
    static readonly type = '[Application Form] Create Application';
}

export class LoadApplication {
    static readonly type = '[Application Form] Load Application';
    constructor(public id: string) {}
}

export class SyncAttachments {
    static readonly type = '[Application Form] Sync Attachments';
}

export class LoadAttachments {
    static readonly type = '[Application Form] Load Attachments';
    constructor(public applicationId: string) {}
}

export class DeleteAllAttachments {
    static readonly type = '[Application Form] Delete All Attachments';
    constructor(public error = false) {}
}

export class DownloadAttachment {
    static readonly type = '[Application Form] Download Attachment';
    constructor(public fileName: string) {}
}

export class LoadWorkersCouncilMembers {
    static readonly type = '[Application Form] Load Delegatable Users';
    constructor(public search: string, public page: number) {}
}

export class SetDelegatedUser {
    static readonly type = '[Application Form] Set Delegate User';
    constructor(public user: GbrUser) {}
}

export class LoadDelegateUser {
    static readonly type = '[Application Form] Load Delegate User';
}
