<div class="footer-wrapper bg-transparent sticky bottom-0">
    <div class="footer">
        <div>© Siemens AG {{ year }}</div>
        <a class="footer-link" [href]="'https://www.siemens.com/global/en/general/legal.html'" target="_blank">
            Corporate Information
        </a>
        <a class="footer-link" [href]="'https://www.siemens.com/global/en/general/privacy-notice.html'" target="_blank">
            Privacy Notice
        </a>
        <a class="footer-link" [href]="'https://www.siemens.com/global/en/general/cookie-notice.html'" target="_blank">
            Cookie Notice
        </a>
        <a class="footer-link" [href]="'https://www.siemens.com/global/en/general/terms-of-use.html'" target="_blank">
            Terms of Use
        </a>
        <a class="footer-link" [href]="'https://www.siemens.com/global/en/general/digital-id.html'" target="_blank">
            Digital ID
        </a>
    </div>
</div>
