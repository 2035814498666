import { HttpClient } from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AppError } from '../errors/app.error';
import { HttpHelperService } from './http-helper.service';

export class ResourceService<T> {
    protected baseUrl = environment.apiUrl;
    constructor(
        protected httpClient: HttpClient,
        protected httpHelperService: HttpHelperService,
        protected resourceTypeName: string
    ) {}

    public getAll(): Observable<T[]> {
        return this.httpClient
            .get<T[]>(`${this.baseUrl}/${this.resourceTypeName}/all`)
            .pipe(catchError(error => throwError(() => new AppError(error))));
    }

    public get(id: string | number): Observable<T> {
        return this.httpClient
            .get<T>(`${this.baseUrl}/${this.resourceTypeName}/${id}`)
            .pipe(catchError(error => throwError(() => new AppError(error))));
    }

    public create(resource: Partial<T>): Observable<T> {
        return this.httpClient
            .post<T>(`${this.baseUrl}/${this.resourceTypeName}/create`, resource)
            .pipe(catchError(error => throwError(() => new AppError(error))));
    }

    public update(resource: Partial<T> & { id: string | number }): Observable<T> {
        return this.httpClient
            .put<T>(`${this.baseUrl}/${this.resourceTypeName}/update`, resource)
            .pipe(catchError(error => throwError(() => new AppError(error))));
    }

    public delete(id: string | number): Observable<void> {
        return this.httpClient
            .delete<void>(`${this.baseUrl}/${this.resourceTypeName}/${id}`)
            .pipe(catchError(error => throwError(() => new AppError(error))));
    }
}
