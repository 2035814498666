export class GetApplications {
    static type = '[Applications] Load';
}

export class GetSubmittedApplications {
    static type = '[Applications] Load Submitted';
}

export class LazyLoadApplication {
    static type = '[Applications] Lazy Load';
}

export class DeleteApplication {
    static type = '[Applications] Delete';
    constructor(public applicationId: string) {}
}

export class CancelApplication {
    static type = '[Applications] Cancel';
    constructor(public applicationId: string) {}
}
