import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Application, UserApplication } from 'libs/interfaces/src';
import { Injectable } from '@angular/core';
import { HttpHelperService } from './http-helper.service';
import { environment } from '../../../environments/environment';
import { CreateApplication } from '../../dashboard/application-process/models/application.model';

@Injectable({
    providedIn: 'root'
})
export class ApplicationService {
    protected baseUrl = environment.apiUrl;

    constructor(private httpClient: HttpClient, private httpHelperService: HttpHelperService) {}

    public create(resource: CreateApplication): Observable<Application> {
        return this.httpClient
            .post<Application>(`${this.baseUrl}/applications`, resource)
            .pipe(this.httpHelperService.handleError(`Could not create application`));
    }

    public update(id: string, resource: CreateApplication): Observable<Application> {
        return this.httpClient
            .patch<Application>(`${this.baseUrl}/applications/${id}`, resource)
            .pipe(this.httpHelperService.handleError(`Could not update application`));
    }

    public get(id: string): Observable<UserApplication<number>> {
        return this.httpClient
            .get<UserApplication<number>>(`${this.baseUrl}/applications/${id}`)
            .pipe(this.httpHelperService.handleError(`Could not get application`));
    }

    public submit(id: string): Observable<Application> {
        return this.httpClient.post<Application>(`${this.baseUrl}/applications/submit/${id}`, {});
    }
}
