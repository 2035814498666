import { HttpClient } from '@angular/common/http';
import { forkJoin, map, Observable, switchMap, tap } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpHelperService } from './http-helper.service';
import { UserAttachment } from '../interfaces/Attachments';
import { environment } from '../../../environments/environment';
import { ListAttachmentsResponse } from '../../dashboard/application-process/models/attachments.model';
import { AttachmentOperations } from '../operations/attachment.operations';
import { AttachmentCategory } from 'interfaces/enums';

@Injectable({
    providedIn: 'root'
})
export class AttachmentService {
    protected baseUrl = environment.apiUrl;

    constructor(protected httpClient: HttpClient, protected httpHelperService: HttpHelperService) {}

    public getAttachments(
        applicationId: string,
        attachmentCategory?: AttachmentCategory
    ): Observable<UserAttachment[]> {
        return this.httpClient
            .get<ListAttachmentsResponse[]>(
                `${this.baseUrl}/applications/${applicationId}/attachments${
                    attachmentCategory ? `/${attachmentCategory}` : ''
                }`
            )
            .pipe(
                map(attachments =>
                    attachments
                        .filter(attachment => !attachment.Key.endsWith('/') && attachment.Metadata.attachmentcategory)
                        .map(attachment => ({
                            name: attachment.Key.split('/')[2],
                            attachmentCategory: attachmentCategory ?? attachment.Metadata.attachmentcategory!
                        }))
                ),
                this.httpHelperService.handleError(`Could not fetch attachments`)
            );
    }

    public uploadAttachment(
        applicationId: string,
        file: File,
        attachmentCategory: AttachmentCategory
    ): Observable<void> {
        return this.httpClient
            .get<{ signedUrl: string }>(
                `${this.baseUrl}/applications/${applicationId}/attachments/${attachmentCategory}/${file.name}/links/upload?contentType=${file.type}`
            )
            .pipe(
                this.httpHelperService.handleError(`Could not get signed URL for upload`),
                switchMap(({ signedUrl }) =>
                    this.httpClient
                        .put<void>(signedUrl, file)
                        .pipe(this.httpHelperService.handleError(`Could not upload file`))
                )
            );
    }

    public downloadAttachment(applicationId: string, attachment: UserAttachment): Observable<{ signedUrl: string }> {
        const { attachmentCategory, name } = attachment;
        return this.httpClient
            .get<{ signedUrl: string }>(
                `${this.baseUrl}/applications/${applicationId}/attachments/${attachmentCategory}/${name}/links/download`
            )
            .pipe(
                this.httpHelperService.handleError(`Could not get signed URL for download`),
                tap(({ signedUrl }) => AttachmentOperations.downloadFileByUrl(signedUrl, name))
            );
    }

    public deleteAttachment(applicationId: string, attachment: UserAttachment): Observable<void> {
        const { attachmentCategory, name } = attachment;
        return this.httpClient
            .delete<void>(`${this.baseUrl}/applications/${applicationId}/attachments/${attachmentCategory}/${name}`)
            .pipe(this.httpHelperService.handleError(`Could not delete attachment`));
    }

    public deleteAllAttachments(applicationId: string, attachments: UserAttachment[]): Observable<void[]> {
        const observables = attachments
            .filter(attachment => attachment.attachmentCategory !== AttachmentCategory.CONSENT)
            .map(attachment => this.deleteAttachment(applicationId, attachment));

        return forkJoin(observables).pipe(this.httpHelperService.handleError(`Could not delete attachments`));
    }
}
