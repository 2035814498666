import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResourceService } from './resource.service';
import { Injectable } from '@angular/core';
import { HttpHelperService } from './http-helper.service';
import { Season, SalaryRangeItem, Page } from 'interfaces/app';

@Injectable({
    providedIn: 'root'
})
export class SeasonService extends ResourceService<Season> {
    constructor(protected override httpClient: HttpClient, protected override httpHelperService: HttpHelperService) {
        super(httpClient, httpHelperService, 'seasons');
    }

    public getActive(page: number, pageSize: number): Observable<Page<Season>> {
        const params = {
            page,
            pageSize
        };
        return this.httpClient
            .get<Page<Season>>(`${this.baseUrl}/seasons/active`, { params })
            .pipe(this.httpHelperService.handleError(`Could not fetch active seasons`));
    }

    public getActiveSeasonsWithoutSubmittedApplicationsByUser(
        page: number,
        pageSize: number,
        gid: string
    ): Observable<Page<Season>> {
        const params = {
            page,
            pageSize
        };
        return this.httpClient
            .get<Page<Season>>(`${this.baseUrl}/users/${gid}/seasons/available`, { params })
            .pipe(
                this.httpHelperService.handleError(
                    `Could not fetch active seasons without submitted applications by user`
                )
            );
    }

    public getSalaryRangeItems(seasonId: string): Observable<SalaryRangeItem[]> {
        return this.httpClient
            .get<SalaryRangeItem[]>(`${this.baseUrl}/seasons/${seasonId}/salary-range-items`)
            .pipe(this.httpHelperService.handleError(`Could not fetch salary range items`));
    }
}
