<div class="flex flex-col bg-[#00183B]">
    <div class="h-screen w-screen relative flex flex-col items-center">
        <img
            class="object-cover opacity-30"
            ngSrc="../../assets/images/customer-home-background.webp"
            alt="Home background image"
            priority
            fill
        />

        <div class="absolute top-0 left-0 right-0 bottom-0 w-full h-full flex items-center justify-center">
            <div class="w-full h-full max-w-[1400px] p-8 flex flex-col justify-center">
                <div>
                    <h6 class="font-subHeading text-subHeadingBold !font-normal !text-base">{{ 'home.welcome' | translate }}</h6>
                    <h3 class="font-subHeadingBold text-subHeadingBold !font-normal !text-base">{{ 'home.app-name' | translate }}</h3>
                </div>
                <div class="mt-4 max-w-[500px]">
                    {{ 'home.subheadline' | translate }}
                </div>
                <div class="mt-4">
                    <button sag-ui-button (click)="navigateToDashboard()">
                        <span class="icon-chevron-right pr-1"></span>
                        <span>{{ 'home.apply' | translate }}</span>
                    </button>
                </div>
            </div>
        </div>
        <div>
            <div
                class="icon-container absolute bottom-0 left-0 right-0 flex items-center justify-center"
                (click)="scrollToNextSection()"
            >
            <button class="mouse"></button>
        </div>
        </div>

        <img
            [src]="'assets/images/next-siemens-logo.svg'"
            class="absolute bottom-10 right-10 h-20"
            alt="Home background image"
        />
    </div>
    <div class="relative w-full overflow-hidden" id="home-trail">
        <section class="grid grid-cols-1 md:grid-cols-[1fr_1fr] gap-4 max-w-[1440px] md:h-[600px] m-auto">
            <div class="flex flex-col items-start justify-center p-8">
                <div class="text-subHeadingAction text-[40px] font-bold mb-8">
                    {{ 'home.bullets.intro' | translate }}
                </div>
                <div class="flex flex-col">
                    <span class="font-subHeading text-subHeadingBold" id="heading1"
                        >{{ 'home.bullets.bullet-1.title-start' | translate }}
                    </span>
                    <span class="font-subHeading text-subHeadingBold">{{
                        'home.bullets.bullet-1.title-end' | translate
                    }}</span>
                </div>
                <div class="font-light">
                    {{ 'home.bullets.bullet-1.description' | translate }}
                </div>
            </div>
            <div class="flex items-center justify-center p-8">
                <img src="assets/images/people-group.png" alt="A group of people on vacation" class="w-3/4" />
            </div>
        </section>
        <section class="grid grid-cols-1 md:grid-cols-[1fr_1fr] gap-4 max-w-[1440px] md:h-[600px] m-auto">
            <div class="flex items-center justify-center p-8">
                <img src="assets/images/prototype.png" alt="Website prototype" />
            </div>
            <div class="flex flex-col items-start justify-center p-8">
                <div class="flex flex-col">
                    <span class="font-subHeading text-subHeadingBold"
                        >{{ 'home.bullets.bullet-2.title-start' | translate }}
                    </span>
                    <span class="font-subHeading text-subHeadingBold">{{
                        'home.bullets.bullet-2.title-end' | translate
                    }}</span>
                </div>
                <div class="font-light">
                    {{ 'home.bullets.bullet-2.description' | translate }}
                </div>
            </div>
        </section>
        <section class="grid grid-cols-1 md:grid-cols-[1fr_1fr] gap-4 max-w-[1440px] md:h-[600px] m-auto">
            <div class="flex flex-col justify-center p-8">
                <div class="flex flex-col">
                    <span class="font-subHeading text-subHeadingBold" id="heading2"
                        >{{ 'home.bullets.bullet-3.title-start' | translate }}
                    </span>
                    <span class="font-subHeading text-subHeadingBold">{{
                        'home.bullets.bullet-3.title-end' | translate
                    }}</span>
                </div>
                <div class="w-[570px] font-light">
                    {{ 'home.bullets.bullet-3.description' | translate }}
                </div>
            </div>
            <div class="flex items-center justify-center p-8">
                <img src="assets/images/home_step_3.png" class="w-3/4" alt="Man sitting on a beach" />
            </div>
        </section>
        <section class="max-w-[1440px] md:h-[400px] m-auto flex flex-col items-center justify-center">
            <div class="p-8">
                <div class="flex flex-col">
                    <span class="font-subHeading text-subHeadingBold"
                        >{{ 'home.bullets.bullet-4.title-start' | translate }}
                    </span>
                    <span class="font-subHeading text-subHeadingBold">{{
                        'home.bullets.bullet-4.title-end' | translate
                    }}</span>
                </div>
                <div class="w-[570px] font-light">
                    {{ 'home.bullets.bullet-4.description' | translate }}
                </div>
            </div>
        </section>
        <section class="grid grid-cols-1 md:grid-cols-[1fr_1fr] gap-4 max-w-[1440px] md:h-[600px] m-auto">
            <div class="flex items-center justify-center p-8">
                <img src="assets/images/circle_group.png" class="w-3/4" alt="" />
            </div>
            <div class="flex flex-col items-start justify-center p-8">
                <div class="flex flex-col">
                    <span class="font-subHeading text-subHeadingBold"
                        >{{ 'home.bullets.bullet-5.title-start' | translate }}
                    </span>
                    <span class="font-subHeading text-subHeadingBold">{{
                        'home.bullets.bullet-5.title-end' | translate
                    }}</span>
                </div>
                <div class="font-light">
                    {{ 'home.bullets.bullet-5.description' | translate }}
                </div>
                <div class="mt-4">
                    <button color="primary" sag-ui-button (click)="navigateToDashboard()">
                        <span class="icon-chevron-right pr-1"></span>
                        <span> {{ 'home.apply' | translate }}</span>
                    </button>
                </div>
            </div>
        </section>

        <!-- Connection Lines -->
        <div class="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center pointer-events-none">
            <div class="w-full h-full relative max-w-[1440px]">
                <div class="hidden 2xl:block absolute top-[320px] -left-[64px]">
                    <img [ngSrc]="'assets/images/vector-1.svg'" width="876" height="517" alt="Connection line" />
                </div>
                <div class="hidden 2xl:block absolute top-[1200px] -left-[80px]">
                    <img [ngSrc]="'assets/images/vector-2.svg'" width="376" height="165" alt="Connecting line" />
                </div>
                <div class="hidden 2xl:block absolute top-[1500px] left-[210px]">
                    <img [ngSrc]="'assets/images/vector-3.svg'" width="529" height="355" alt="Connecting line" />
                </div>
                <div class="hidden 2xl:block absolute top-[2020px] left-[690px]">
                    <img [ngSrc]="'assets/images/vector-4.svg'" width="608" height="320" alt="Connecting line" />
                </div>

                <!-- Clouds -->
                <img
                    class="hidden lg:block absolute top-[32px] xl:top-[200px] left-[60px] xl:-left-[70px] scale-x-[-1] aspect-square w-[73px]"
                    [ngSrc]="'assets/images/cloud.svg'"
                    width="73"
                    height="73"
                    alt="Cloud image"
                />

                <img
                    class="hidden 2xl:block absolute top-[1960px] left-[60px] scale-x-[-1] aspect-square w-[110px]"
                    [ngSrc]="'assets/images/cloud.svg'"
                    width="110"
                    height="110"
                    alt="Cloud image"
                />
            </div>
        </div>
    </div>
</div>
