<div class="flex flex-col items-center gap-4 p-4 relative">
    <h3>{{ translationPath + 'title' | translate }}</h3>
    <p>{{ translationPath + 'desc' | translate }}</p>
    <div class="flex gap-12">
        <sag-ui-button class="w-[120px] h-[50px]" (click)="openLinkInNewTab(true)">
            <div class="flex gap-4 p-1 items-center justify-center">
                <span class="material-symbols-outlined text-xl text-black"> quiz </span>
                <span>{{ translationPath + 'faqButton' | translate }}</span>
            </div>
            </sag-ui-button>
        <sag-ui-button class="w-[120px] h-[50px]" (click)="openLinkInNewTab(false)">
            <div class="flex gap-4 p-1 items-center justify-center">
                <span class="material-symbols-outlined text-xl text-black"> support_agent </span>
                <span>{{ translationPath + 'helpDeskButton' | translate }}</span>
            </div>
        </sag-ui-button>
    </div>
    <sag-ui-button class="absolute top-1 right-0" (click)="onClose()" color="tertiary">X</sag-ui-button>
</div>
