import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { SCDUser, User } from '../interfaces/User';
import { ScdPickerSearchQuery } from '@siemens/component-lib';
import { Page } from 'interfaces/app';
import { LanguageCode } from 'interfaces/enums';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private apiUrl = `${environment.apiUrl}/users`;
    constructor(private httpClient: HttpClient) {}

    public getUserFromScd(query: { gid?: string; mail?: string }): Observable<SCDUser | null> {
        return this.httpClient.get<SCDUser>(`${this.apiUrl}/scd`, { params: query }).pipe(catchError(() => of(null)));
    }

    public getUsersFromScd(query: ScdPickerSearchQuery): Observable<SCDUser[]> {
        const { gid, firstName, lastName } = query;

        const params = {
            gid,
            firstname: firstName,
            surname: lastName
        } as {
            gid?: string;
            firstname?: string;
            surname?: string;
        };

        if (!params.gid) delete params.gid;
        if (!params.firstname) delete params.firstname;
        if (!params.surname) delete params.surname;

        return this.httpClient.get<SCDUser[]>(`${this.apiUrl}/scd/all`, { params: params }).pipe(
            catchError(() => of(null)),
            map(response => response ?? [])
        );
    }

    public getMe(): Observable<User> {
        return this.httpClient.get<User>(`${environment.apiUrl}/me`);
    }

    public postMe(): Observable<User> {
        return this.httpClient.post<User>(`${environment.apiUrl}/me`, {});
    }

    public patchMe(language: LanguageCode): Observable<User> {
        return this.httpClient.patch<User>(`${environment.apiUrl}/me`, { language });
    }

    public getWorkerCouncilMembers(groups: string[], page: number, search?: string): Observable<Page<User>> {
        const params = {
            groups: groups.join(','),
            page: page.toString()
        } as {
            groups: string;
            page: string;
            search?: string;
        };

        if (search) {
            params['search'] = search;
        }

        return this.httpClient.get<Page<User>>(`${environment.apiUrl}/workers-council-groups/users`, { params });
    }
}
