import { Injectable } from '@angular/core';
import { catchError, filter, map, Observable, of, switchMap, tap } from 'rxjs';
import { IJwtIdTokenClaims, GbrUser } from 'interfaces/src';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { LoadProfilePicture, SetUser } from '../state/user.action';
import { UserService } from '../../shared/services/user.service';
import { environment } from '../../../environments/environment';
import { User } from '../../shared/interfaces/User';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    public isAuthenticated$: Observable<boolean>;
    private gbrUser: GbrUser;

    constructor(
        private securityService: OidcSecurityService,
        private userService: UserService,
        private store$: Store,
        private router: Router
    ) {
        this.isAuthenticated$ = this.securityService.checkAuth().pipe(
            filter(user => !!user?.userData),
            tap(user => this.isGermanUser(user.userData)),
            switchMap(user => this.getUserRoles(user.userData)),
            tap(auth => this.dispatchUser(auth.isAuthenticated, auth.user)),
            tap(() => this.loadProfilePicture()),
            map(auth => auth.isAuthenticated)
        );
    }

    public login(): void {
        const url = window.location.pathname + window.location.search;
        window.localStorage.setItem('redirectUrl', url);
        this.securityService.authorize();
    }

    public get accessToken$() {
        return this.securityService.getAccessToken();
    }

    private dispatchUser(isAuthenticated: boolean, user: GbrUser): void {
        this.store$.dispatch(
            new SetUser({
                isAuthenticated,
                user
            })
        );
    }

    private getUserRoles(user: IJwtIdTokenClaims): Observable<{ user: GbrUser; isAuthenticated: boolean }> {
        return this.userService.getMe().pipe(
            map(authUser => ({ user: this.mapUserToGbrUser(user, authUser), isAuthenticated: true })),
            catchError(() =>
                of({ user: this.mapUserToGbrUser(user, { roles: ['User'] } as User), isAuthenticated: true })
            )
        );
    }

    private mapUserToGbrUser(authUser: IJwtIdTokenClaims, gbrUser: User): GbrUser {
        return {
            gid: authUser?.['custom:gid'],
            firstName: authUser?.given_name,
            lastName: authUser?.family_name,
            nickName: authUser.nickname,
            email: authUser?.email,
            orgCode: authUser?.['custom:org_code'],
            country: authUser?.['custom:country'],
            roles: gbrUser.roles,
            delegateForGroups: gbrUser.delegateForGroups || []
        } as GbrUser;
    }

    private isGermanUser(user: { 'custom:country': string }): void {
        if (user['custom:country'] === 'DE') {
            return;
        }

        environment.stage === 'prod' && this.router.navigate(['/auth/unauthorized']);
    }

    private loadProfilePicture(): void {
        this.store$.dispatch(new LoadProfilePicture());
    }

    public logout(): void {
        this.securityService.logoffLocal();
        this.securityService.logoffAndRevokeTokens().subscribe();
        this.securityService.logoff().subscribe();
        window.location.reload();
    }
}
