import { Injectable } from '@angular/core';
import { SnackbarService } from '@siemens/component-lib';
import { catchError, Observable, OperatorFunction, throwError } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class HttpHelperService {
    constructor(private snackbarService: SnackbarService) {}

    public handleError<T>(message?: string): OperatorFunction<T, T> {
        return catchError<T, Observable<never>>(error => {
            this.snackbarService.open({
                title: 'Operation unsuccessful',
                description: message ?? 'There was an error while handling your request',
                type: 'error',
                duration: 3000,
                position: 'bottom'
            });
            return throwError(() => new Error(message || (error.error.message as string)));
        });
    }
}
