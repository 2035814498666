import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { ApplicationProcessForm, ApplicationProcessStepper } from '../models/application-process-interfaces';
import {
    AddAttachment,
    CreateApplication,
    DecrementStepper,
    DeleteAllAttachments,
    DownloadAttachment,
    GetActiveSeasons,
    GetSalaryRanges,
    IncrementStepper,
    LoadApplication,
    LoadAttachments,
    LoadDelegateUser,
    LoadWorkersCouncilMembers,
    RemoveAttachment,
    ResetApplicationForm,
    ResetStepper,
    SaveApplication,
    SetDelegatedUser,
    SetStepper,
    SubmitApplicationForm,
    SyncAttachments
} from './application-process.action';
import { GbrUser, SalaryRangeItem, Season, UserApplication } from 'libs/interfaces/src';
import { SeasonService } from '../../../shared/services/season.service';
import { concatMap, EMPTY, filter, Observable, of, switchMap, tap } from 'rxjs';
import { AvailableDates } from '../../../shared/interfaces/Season';
import { UserAttachment } from '../../../shared/interfaces/Attachments';
import { patch } from '@ngxs/store/operators';
import { ApplicationService } from '../../../shared/services/application.service';
import { ApplicationProcessCreateOperations } from '../operations/application-process.operations';
import { UpdateFormValue } from '@ngxs/form-plugin';
import { UserState } from '../../../auth/state/user.state';
import { AttachmentService } from '../../../shared/services/attachment.service';
import { User } from '../../../shared/interfaces/User';
import { UserService } from '../../../shared/services/user.service';
import { ApplicationStatus } from '../models/application.model';

interface ApplicationProcessStateModel {
    applicationProcessForm: {
        model?: ApplicationProcessForm;
    };
    stepper: ApplicationProcessStepper;
    activeSeasons: {
        seasons: Season[];
        startDate: Date | null;
        endDate: Date | null;
    };
    application: UserApplication<number> | null;
    salaryRangeItems: SalaryRangeItem[];
    attachmentsToRemove: UserAttachment[];
    selectedApplicationId: string | null;
    workersCouncilMembers: User[];
    delegateUserGid: string | null;
    delegateUser: GbrUser | null;
    employeeGid: string | null;
    employeeUser: GbrUser | null;
}

@State<ApplicationProcessStateModel>({
    name: 'application',
    defaults: {
        applicationProcessForm: {
            model: undefined
        },
        stepper: {
            currentStep: 0,
            selected: 0,
            active: 0
        },
        activeSeasons: {
            seasons: [],
            startDate: null,
            endDate: null
        },
        application: null,
        salaryRangeItems: [],
        attachmentsToRemove: [],
        selectedApplicationId: null,
        workersCouncilMembers: [],
        delegateUserGid: null,
        delegateUser: null,
        employeeGid: null,
        employeeUser: null
    }
})
@Injectable()
export class ApplicationProcessState {
    constructor(
        private seasonService: SeasonService,
        private applicationService: ApplicationService,
        private attachmentsService: AttachmentService,
        private userService: UserService,
        private store: Store
    ) {}

    @Selector()
    static getStepper(state: ApplicationProcessStateModel): ApplicationProcessStepper {
        return state.stepper;
    }

    @Selector()
    static getForm(state: ApplicationProcessStateModel): ApplicationProcessForm | undefined {
        return state.applicationProcessForm.model;
    }

    @Selector()
    static getActiveSeasons(state: ApplicationProcessStateModel): Season[] {
        return state.activeSeasons.seasons;
    }

    @Selector()
    static getAvailableDates(state: ApplicationProcessStateModel): AvailableDates | null {
        if (!state.activeSeasons.startDate || !state.activeSeasons.endDate) {
            return null;
        }

        const endDate = new Date(state.activeSeasons.endDate);
        const today = new Date();

        return {
            startDate: new Date(state.activeSeasons.startDate),
            endDate: endDate > today ? today : endDate
        };
    }

    @Selector()
    static getSalaryRangeItems(state: ApplicationProcessStateModel): SalaryRangeItem[] {
        return state.salaryRangeItems;
    }

    @Selector()
    static getAttachments(state: ApplicationProcessStateModel): UserAttachment[] {
        return state.applicationProcessForm.model?.invoices.attachments || [];
    }

    @Selector()
    static getSelectedApplicationId(state: ApplicationProcessStateModel): string | null {
        return state.selectedApplicationId;
    }

    @Selector()
    static getWorkersCouncilMembers(state: ApplicationProcessStateModel): User[] {
        return state.workersCouncilMembers;
    }

    @Selector()
    static getWorkersCouncilMembersOptions(state: ApplicationProcessStateModel): { label: string; value: User }[] {
        return state.workersCouncilMembers.map(user => ({
            label: `${user.firstName} ${user.lastName} (${user.gid})`,
            value: user
        }));
    }

    @Selector()
    static getDelegatedUser(state: ApplicationProcessStateModel): GbrUser | null {
        return state.delegateUser;
    }

    @Selector()
    static getEmployeeUser(state: ApplicationProcessStateModel): GbrUser | null {
        return state.employeeUser;
    }

    @Selector()
    static getApplication(state: ApplicationProcessStateModel): UserApplication<number> | null {
        return state.application;
    }

    @Action(IncrementStepper)
    IncrementStepper(context: StateContext<ApplicationProcessStateModel>) {
        const currentStepperValue = context.getState().stepper;
        context.patchState({
            stepper: {
                currentStep: currentStepperValue.currentStep + 1,
                active: currentStepperValue.active + 1,
                selected: currentStepperValue.selected + 1
            }
        });
    }

    @Action(DecrementStepper)
    decrementStepper(context: StateContext<ApplicationProcessStateModel>) {
        const currentStepperValue = context.getState().stepper;
        context.patchState({
            stepper: {
                currentStep: currentStepperValue.currentStep - 1,
                active: currentStepperValue.active - 1,
                selected: currentStepperValue.selected - 1
            }
        });
    }
    @Action(ResetStepper)
    resetStepper(context: StateContext<ApplicationProcessStateModel>) {
        context.patchState({
            stepper: {
                currentStep: 0,
                active: 0,
                selected: 0
            }
        });
    }

    @Action(SetStepper)
    setStepper(context: StateContext<ApplicationProcessStateModel>, action: SetStepper) {
        context.patchState({
            stepper: {
                currentStep: action.step,
                active: action.step,
                selected: action.step
            }
        });
    }

    @Action(ResetApplicationForm)
    resetForm(context: StateContext<ApplicationProcessStateModel>) {
        context.patchState({
            selectedApplicationId: null,
            application: null,
            employeeGid: null,
            delegateUserGid: null,
            employeeUser: null,
            delegateUser: null,
            attachmentsToRemove: [],
            applicationProcessForm: {
                model: {
                    personalDetails: {
                        privateEmailAddress: '',
                        isSingleParent: null,
                        isOnParentalLeave: null,
                        isCaregiver: null,
                        isShiftWorker: null,
                        hasSevereDisability: null,
                        isPartTimeOrPartialRetirement: null
                    },
                    travelDates: {
                        startDate: null,
                        endDate: null
                    },
                    incomeRange: {
                        monthlyIncomeRange: ''
                    },
                    spouse: {
                        hasTraveledWithSpouse: null,
                        spouseName: '',
                        hasSpouseSevereDisability: null,
                        isSpouseSiemensEmployee: null,
                        spouseGid: ''
                    },
                    children: {
                        hasTraveledWithChild: null,
                        children: []
                    },
                    invoices: {
                        attachments: []
                    },
                    summary: {
                        confirmationOfDataCorrectness: false
                    }
                }
            }
        });
    }

    @Action(GetActiveSeasons)
    getActiveSeasons(context: StateContext<ApplicationProcessStateModel>) {
        return this.seasonService.getActive().pipe(
            tap(result => {
                context.patchState({
                    activeSeasons: {
                        seasons: result.items,
                        startDate: result.availableDates.startDate,
                        endDate: result.availableDates.endDate
                    }
                });
            })
        );
    }

    @Action(GetSalaryRanges)
    getSalaryRanges(context: StateContext<ApplicationProcessStateModel>, { payload }: GetSalaryRanges) {
        const startDate = payload?.startDate ?? context.getState().applicationProcessForm.model?.travelDates.startDate;
        const endDate = payload?.endDate ?? context.getState().applicationProcessForm.model?.travelDates.endDate;

        if (!startDate || !endDate) {
            return;
        }

        return this.seasonService.getSalaryRangeItems(startDate, endDate).pipe(
            tap(result => {
                context.patchState({
                    salaryRangeItems: result
                });
            })
        );
    }

    @Action(AddAttachment)
    addAttachment(context: StateContext<ApplicationProcessStateModel>, action: AddAttachment) {
        const attachments = context.getState().applicationProcessForm.model?.invoices.attachments || [];
        attachments.push(action.attachment);

        context.setState(
            patch({
                applicationProcessForm: patch({
                    model: patch({
                        invoices: {
                            attachments
                        }
                    })
                })
            })
        );
    }

    @Action(RemoveAttachment)
    removeAttachment(context: StateContext<ApplicationProcessStateModel>, action: RemoveAttachment) {
        const attachments = context.getState().applicationProcessForm.model?.invoices.attachments || [];

        const attachment = attachments[action.index];

        if (!attachment.file) {
            context.patchState({
                attachmentsToRemove: [...context.getState().attachmentsToRemove, attachment]
            });
        }

        attachments.splice(action.index, 1);

        context.setState(
            patch({
                applicationProcessForm: patch({
                    model: patch({
                        invoices: {
                            attachments
                        }
                    })
                })
            })
        );
    }

    @Action(SaveApplication)
    saveApplication(context: StateContext<ApplicationProcessStateModel>): Observable<void> {
        const state = context.getState();

        if (!state.selectedApplicationId) {
            throw new Error('Application ID not found');
        }

        const { seasonId, user } = ApplicationProcessCreateOperations.checkIfFormSaveable(
            state.applicationProcessForm.model!,
            state.activeSeasons.seasons,
            this.store.selectSnapshot(UserState.user)
        );

        const employeeGid = state.employeeGid;
        const delegateGid = state.delegateUserGid;

        return this.applicationService
            .update(
                state.selectedApplicationId,
                ApplicationProcessCreateOperations.mapFormToCreateApplication(
                    state.applicationProcessForm.model!,
                    seasonId,
                    employeeGid ?? user.gid,
                    delegateGid ?? undefined
                )
            )
            .pipe(
                tap(application => {
                    context.patchState({
                        selectedApplicationId: application.id
                    });
                }),
                concatMap(() => context.dispatch(new SyncAttachments()))
            );
    }

    @Action(CreateApplication)
    createApplication(context: StateContext<ApplicationProcessStateModel>): Observable<void> {
        const state = context.getState();

        const { seasonId, user } = ApplicationProcessCreateOperations.checkIfFormSaveable(
            state.applicationProcessForm.model!,
            state.activeSeasons.seasons,
            this.store.selectSnapshot(UserState.user)
        );

        const employeeGid = state.employeeGid;
        const delegateGid = state.delegateUserGid;

        return this.applicationService
            .create(
                ApplicationProcessCreateOperations.mapFormToCreateApplication(
                    state.applicationProcessForm.model!,
                    seasonId,
                    employeeGid ?? user.gid,
                    delegateGid ?? undefined
                )
            )
            .pipe(
                tap(application => {
                    context.patchState({
                        selectedApplicationId: application.id
                    });
                }),
                concatMap(() => context.dispatch(new SyncAttachments()))
            );
    }

    @Action(SubmitApplicationForm)
    submitApplicationForm(context: StateContext<ApplicationProcessStateModel>) {
        const applicationId = context.getState().selectedApplicationId;

        return context
            .dispatch(applicationId ? new SaveApplication() : new CreateApplication())
            .pipe(switchMap(() => this.applicationService.submit(context.getState().selectedApplicationId!)));
    }

    @Action(LoadApplication)
    loadApplication(context: StateContext<ApplicationProcessStateModel>, action: LoadApplication) {
        return this.applicationService.get(action.id).pipe(
            tap(application => {
                context.dispatch(
                    new UpdateFormValue({
                        value: ApplicationProcessCreateOperations.mapApplicationToForm(application),
                        path: 'application.applicationProcessForm'
                    })
                );

                context.patchState({
                    application,
                    selectedApplicationId: application.id,
                    delegateUserGid: application.delegateGid ?? null,
                    employeeGid: application.employeeGid,
                    employeeUser: application.employee as GbrUser
                });
            })
        );
    }

    @Action(LoadDelegateUser)
    loadDelegatedUser(context: StateContext<ApplicationProcessStateModel>) {
        const employeeGid = context.getState().employeeGid;
        const delegateUserGid = context.getState().delegateUserGid;

        if (!employeeGid || !delegateUserGid) {
            return EMPTY;
        }

        return this.userService.getUserFromScd({ gid: delegateUserGid }).pipe(
            filter(user => !!user),
            tap(delegateUser => {
                context.patchState({
                    delegateUser: {
                        firstName: delegateUser!.firstName,
                        lastName: delegateUser!.surName,
                        gid: delegateUser!.gid,
                        orgCode: (delegateUser as any)['custom:orgCode']
                    } as unknown as GbrUser
                });
            })
        );
    }

    @Action(SyncAttachments)
    syncAttachments(context: StateContext<ApplicationProcessStateModel>) {
        const attachmentsToRemove = context.getState().attachmentsToRemove;
        const attachments = context.getState().applicationProcessForm.model?.invoices.attachments || [];
        const attachmentsToUpload = attachments.filter(attachment => attachment.file);
        const user = this.store.selectSnapshot(UserState.user);
        const employeeGid = context.getState().employeeGid;

        if (!user) {
            return of(null);
        }

        return of(null).pipe(
            concatMap(() =>
                attachmentsToRemove.length > 0
                    ? this.attachmentsService.deleteAttachment(
                          context.getState().selectedApplicationId!,
                          employeeGid ?? user.gid,
                          attachmentsToRemove[0].name!
                      )
                    : of(null)
            ),
            concatMap(() =>
                attachmentsToUpload.length > 0
                    ? this.attachmentsService.uploadAttachment(
                          context.getState().selectedApplicationId!,
                          employeeGid ?? user.gid,
                          attachmentsToUpload[0].file!
                      )
                    : of(null)
            )
        );
    }

    @Action(LoadAttachments)
    loadAttachments(context: StateContext<ApplicationProcessStateModel>, action: LoadAttachments) {
        const user = this.store.selectSnapshot(UserState.user);
        const employeeGid = context.getState().employeeGid;

        if (!user) {
            return of(null);
        }

        return this.attachmentsService.getAttachments(action.applicationId, employeeGid ?? user.gid).pipe(
            tap(attachments => {
                context.setState(
                    patch({
                        applicationProcessForm: patch({
                            model: patch({
                                invoices: {
                                    attachments
                                }
                            })
                        })
                    })
                );
            })
        );
    }

    @Action(DeleteAllAttachments)
    deleteAttachments(context: StateContext<ApplicationProcessStateModel>, action: DeleteAllAttachments) {
        const attachments = context.getState().applicationProcessForm.model?.invoices.attachments || [];
        const applicationStatus = context.getState().application?.status;
        const user = this.store.selectSnapshot(UserState.user);
        const employeeGid = context.getState().employeeGid;

        if (!user) {
            return of(null);
        }

        if (attachments.length === 0) {
            return of(null);
        }

        if (
            action.error &&
            (applicationStatus === ApplicationStatus.DRAFT || applicationStatus === ApplicationStatus.CANCELED)
        ) {
            return of(null);
        }

        return this.attachmentsService
            .deleteAllAttachments(
                context.getState().selectedApplicationId!,
                employeeGid ?? user.gid,
                attachments.map(attachment => attachment.name!)
            )
            .pipe(
                tap(() => {
                    context.setState(
                        patch({
                            applicationProcessForm: patch({
                                model: patch({
                                    invoices: {
                                        attachments: []
                                    }
                                })
                            })
                        })
                    );
                })
            );
    }

    @Action(DownloadAttachment)
    downloadAttachment(context: StateContext<ApplicationProcessStateModel>, action: DownloadAttachment) {
        const user = this.store.selectSnapshot(UserState.user);
        const employeeGid = context.getState().employeeGid;

        if (!user) {
            return of(null);
        }

        return this.attachmentsService.downloadAttachment(
            context.getState().selectedApplicationId!,
            employeeGid ?? user.gid,
            action.fileName
        );
    }

    @Action(LoadWorkersCouncilMembers)
    loadDelegatableUsers(context: StateContext<ApplicationProcessStateModel>, action: LoadWorkersCouncilMembers) {
        const { search, page } = action;

        const user = this.store.selectSnapshot(UserState.user);
        const groups = user?.delegateForGroups?.map(group => group.id) || [];

        return this.userService.getWorkerCouncilMembers(groups, page, search).pipe(
            tap(users => {
                if (page === 0) {
                    context.patchState({
                        workersCouncilMembers: users
                    });
                } else {
                    context.patchState({
                        workersCouncilMembers: [...context.getState().workersCouncilMembers, ...users]
                    });
                }
            })
        );
    }

    @Action(SetDelegatedUser)
    setDelegateUser(context: StateContext<ApplicationProcessStateModel>, action: SetDelegatedUser) {
        const user = this.store.selectSnapshot(UserState.user);

        context.patchState({
            employeeUser: action.user,
            employeeGid: action.user.gid,
            delegateUser: user,
            delegateUserGid: user?.gid
        });
    }
}
