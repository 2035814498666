import { Injectable } from '@angular/core';
import { catchError, map, Observable, of, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { SCDUser, User } from '../interfaces/User';
import { Page } from 'libs/interfaces/src';
import { ScdPickerSearchQuery } from '@siemens/component-lib';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private apiUrl = `${environment.apiUrl}/users`;
    constructor(private httpClient: HttpClient) {}

    public getUserFromScd(query: { gid?: string; mail?: string }): Observable<SCDUser | null> {
        return this.httpClient.get<SCDUser>(`${this.apiUrl}/scd`, { params: query }).pipe(catchError(() => of(null)));
    }

    public getUsersFromScd(query: ScdPickerSearchQuery): Observable<SCDUser[]> {
        const { gid, firstName, lastName } = query;

        const params = {
            gid,
            firstname: firstName,
            surname: lastName
        } as {
            gid?: string;
            firstname?: string;
            surname?: string;
        };

        if (!params.gid) delete params.gid;
        if (!params.firstname) delete params.firstname;
        if (!params.surname) delete params.surname;

        return this.httpClient.get<SCDUser[]>(`${this.apiUrl}/scd/all`, { params: params }).pipe(
            catchError(() => of(null)),
            map(response => response ?? [])
        );
    }

    public getMe(): Observable<User> {
        return this.httpClient.get<User>(`${environment.apiUrl}/me`);
    }

    public getProfilePicture(): Observable<string | undefined> {
        return this.httpClient
            .get<{ data: string; encoding: string; contentType: string }>(`${environment.apiUrl}/me/profile-picture`)
            .pipe(
                map(response => `data:${response.contentType};${response.encoding},${response.data}`),
                catchError(() => of(undefined))
            );
    }

    public getWorkerCouncilMembers(groups: string[], page: number, search?: string): Observable<User[]> {
        const params = {
            groups: groups.join(','),
            page: page.toString()
        } as {
            groups: string;
            page: string;
            search?: string;
        };

        if (search) {
            params['search'] = search;
        }

        return this.httpClient
            .get<Page<User>>(`${environment.apiUrl}/workers-council-groups/users`, {
                params
            })
            .pipe(map(response => response.items));
    }
}
