import { State, Action, StateContext, Selector } from '@ngxs/store';
import { HideScreenTooSmall, ShowScreenTooSmall } from './screen.actions';
import { Injectable } from '@angular/core';

export interface ScreenStateModel {
    isScreenTooSmall: boolean;
    requiredScreenWidth: number;
}

@State<ScreenStateModel>({
    name: 'screen',
    defaults: {
        isScreenTooSmall: false,
        requiredScreenWidth: 1000
    }
})
@Injectable()
export class ScreenState {
    @Selector()
    static isScreenTooSmall(state: ScreenStateModel): boolean {
        return state.isScreenTooSmall;
    }

    @Selector()
    static requiredScreenWidth(state: ScreenStateModel): number {
        return state.requiredScreenWidth;
    }

    @Action(ShowScreenTooSmall)
    showWindowTooSmall(ctx: StateContext<ScreenStateModel>): void {
        ctx.patchState({
            isScreenTooSmall: true
        });
    }

    @Action(HideScreenTooSmall)
    hideWindowTooSmall(ctx: StateContext<ScreenStateModel>): void {
        ctx.patchState({
            isScreenTooSmall: false
        });
    }
}
