import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-screen-too-small',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './screen-too-small.component.html'
})
export class ScreenTooSmallComponent {
    @Input() titleText: string;
    @Input() descriptionText: string;
}
