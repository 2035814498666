import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { HttpHelperService } from '../../shared/services/http-helper.service';
import { Page, UserApplication } from 'interfaces/app';
import { ApplicationStatus } from 'interfaces/enums';

@Injectable({
    providedIn: 'root'
})
export class ApplicationService {
    constructor(private httpClient: HttpClient, private httpHelpersService: HttpHelperService) {}

    public getApplications(
        page: number,
        pageSize: number,
        includeDelegated?: boolean
    ): Observable<Page<UserApplication>> {
        const params = {
            page,
            pageSize,
            orderBy: 'id',
            sortOrder: 'ASC',
            includeDelegated: includeDelegated || false
        };
        return this.httpClient.get<Page<UserApplication>>(`${environment.apiUrl}/me/applications`, {
            params
        });
    }

    public getSubmittedApplications(
        page: number,
        pageSize: number,
        includeDelegated?: boolean
    ): Observable<Page<UserApplication>> {
        const _params = {
            page,
            pageSize,
            orderBy: 'id',
            sortOrder: 'ASC',
            status: [ApplicationStatus.SUBMITTED, ApplicationStatus.APPROVED, ApplicationStatus.DECLINED].toString(),
            includeDelegated: includeDelegated || false
        };
        const params = new HttpParams({ fromObject: _params });
        return this.httpClient
            .get<Page<UserApplication>>(`${environment.apiUrl}/me/applications`, {
                params
            })
            .pipe(this.httpHelpersService.handleError('Could not retrieve submitted applications.'));
    }

    public deleteApplication(id: string): Observable<void> {
        return this.httpClient
            .delete<void>(`${environment.apiUrl}/applications/${id}`)
            .pipe(this.httpHelpersService.handleError(`Could not delete application '${id}'.`));
    }

    public cancelApplication(id: string): Observable<void> {
        return this.httpClient
            .patch<void>(`${environment.apiUrl}/applications/${id}/cancel`, {})
            .pipe(this.httpHelpersService.handleError(`Could not cancel application '${id}'.`));
    }
}
