@if (isScreenTooSmall()) {
<app-screen-too-small
    [titleText]="'general.screen-too-small.title' | translate"
    [descriptionText]="'general.screen-too-small.description' | translate"
></app-screen-too-small>
} @else {
<div class="page-wrapper h-full">
    <app-header [wasScrolled]="wasScrolled"></app-header>
    <div class="content-wrapper grow w-full relative">
        <router-outlet> </router-outlet>
    </div>
    <div class="-rotate-90 origin-bottom-right fixed z-2 right-0 top-1/3">
        <sag-ui-button (click)="openHelpDeskDialog()">
            <div class="flex gap-4 p-1">
                <span class="material-symbols-outlined text-xl text-black"> chat_bubble </span>
                <span>{{ 'home.helpButton' | translate }}</span>
            </div>
        </sag-ui-button>
    </div>

    <app-footer> </app-footer>
</div>
}
