import { Injectable } from '@angular/core';
import { catchError, filter, map, Observable, of, switchMap, tap, throwError } from 'rxjs';
import { IJwtIdTokenClaims, GbrUser } from 'interfaces/src';
import { LanguageCode } from 'interfaces/enums';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { SetUser, SetUserLanguage } from '../state/user.action';
import { UserService } from '../../shared/services/user.service';
import { environment } from '../../../environments/environment';
import { User } from '../../shared/interfaces/User';
import { getEnumValue } from '@gbr-monorepo/backend-lib/helpers';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    public isAuthenticated$: Observable<boolean>;

    constructor(
        private securityService: OidcSecurityService,
        private userService: UserService,
        private store$: Store,
        private router: Router
    ) {
        this.isAuthenticated$ = this.securityService.checkAuth().pipe(
            filter(user => !!user?.userData),
            tap(user => this.isGermanUser(user.userData)),
            switchMap(user => this.getUser(user.userData)),
            tap(auth => this.dispatchUser(auth.isAuthenticated, auth.user, auth.language)),
            map(auth => auth.isAuthenticated)
        );
    }

    public login(): void {
        const url = window.location.pathname + window.location.search;
        window.localStorage.setItem('redirectUrl', url);
        this.securityService.authorize();
    }

    public get accessToken$() {
        return this.securityService.getAccessToken();
    }

    private dispatchUser(isAuthenticated: boolean, user: GbrUser, language: LanguageCode): void {
        this.store$.dispatch([
            new SetUser({
                isAuthenticated,
                user
            }),
            new SetUserLanguage({ language })
        ]);
    }

    private getUser(
        user: IJwtIdTokenClaims
    ): Observable<{ user: GbrUser; isAuthenticated: boolean; language: LanguageCode }> {
        return this.userService.getMe().pipe(
            catchError(error => {
                if (error.status === 404) {
                    return this.userService.postMe();
                }
                return throwError(() => error);
            }),
            map(authUser => ({
                user: this.mapUserToGbrUser(user, authUser),
                isAuthenticated: true,
                language: getEnumValue(LanguageCode, authUser.language) || LanguageCode.DE
            })),
            catchError(() =>
                of({
                    user: this.mapUserToGbrUser(user, { roles: ['User'] } as User),
                    isAuthenticated: true,
                    language: LanguageCode.DE
                })
            )
        );
    }

    private mapUserToGbrUser(authUser: IJwtIdTokenClaims, gbrUser: User): GbrUser {
        return {
            gid: authUser?.['custom:gid'],
            firstName: authUser?.given_name,
            lastName: authUser?.family_name,
            nickName: authUser.nickname,
            email: authUser?.email,
            orgCode: authUser?.['custom:org_code'],
            country: authUser?.['custom:country'],
            roles: gbrUser.roles,
            delegateForGroups: gbrUser.delegateForGroups || []
        } as GbrUser;
    }

    private isGermanUser(user: { 'custom:country': string }): void {
        if (user['custom:country'] === 'DE') {
            return;
        }

        environment.stage === 'prod' && this.router.navigate(['/auth/unauthorized']);
    }

    public logout(): void {
        this.securityService.logoffLocal();
        this.securityService.logoffAndRevokeTokens().subscribe();
        this.securityService.logoff().subscribe();
        window.location.reload();
    }
}
