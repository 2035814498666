import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { ApplicationProcessForm, ApplicationProcessStepper } from '../models/application-process-interfaces';
import {
    AddAttachment,
    CreateApplication,
    DecrementStepper,
    DeleteAllAttachments,
    DownloadAttachment,
    GetAvailableActiveSeasons,
    GetSalaryRanges,
    IncrementStepper,
    LoadApplication,
    LoadAttachments,
    LoadDelegateUser,
    LoadWorkersCouncilMembers,
    RemoveAttachment,
    ResetApplicationForm,
    ResetStepper,
    SaveApplication,
    SetApplicantUser,
    SetDelegatedUser,
    SetPersonalDataUsageAcceptationDate,
    SetStepper,
    SubmitApplicationForm,
    SyncAttachments
} from './application-process.action';
import { SeasonService } from '../../../shared/services/season.service';
import {
    catchError,
    concat,
    concatMap,
    EMPTY,
    expand,
    filter,
    from,
    map,
    Observable,
    of,
    scan,
    switchMap,
    takeWhile,
    tap,
    throwError
} from 'rxjs';
import { UserAttachment } from '../../../shared/interfaces/Attachments';
import { patch } from '@ngxs/store/operators';
import { ApplicationService } from '../../../shared/services/application.service';
import { ApplicationProcessCreateOperations } from '../operations/application-process.operations';
import { UpdateFormValue } from '@ngxs/form-plugin';
import { UserState } from '../../../auth/state/user.state';
import { AttachmentService } from '../../../shared/services/attachment.service';
import { User } from '../../../shared/interfaces/User';
import { UserService } from '../../../shared/services/user.service';
import { Page, SalaryRangeItem, Season, UserApplication } from 'interfaces/app';
import { GbrUser } from 'interfaces/auth';
import { ApplicationStatus, AttachmentCategory } from 'interfaces/enums';
import { AttachmentOperations } from '../../../shared/operations/attachment.operations';

interface ApplicationProcessStateModel {
    applicationProcessForm: {
        model?: ApplicationProcessForm;
    };
    stepper: ApplicationProcessStepper;
    availableActiveSeasons: Season[];
    application: UserApplication | null;
    salaryRangeItems: SalaryRangeItem[];
    attachmentsToRemove: UserAttachment[];
    selectedApplicationId: string | null;
    workersCouncilMembers: User[];
    hasLoadedAllWorkersCouncilMembers: boolean;
    delegateUserGid: string | null;
    delegateUser: GbrUser | null;
    employeeGid: string | null;
    employeeUser: GbrUser | null;
    personalDataUsageAcceptationDate: Date | null;
}

@State<ApplicationProcessStateModel>({
    name: 'application',
    defaults: {
        applicationProcessForm: {
            model: undefined
        },
        stepper: {
            currentStep: 0,
            selected: 0,
            active: 0
        },
        availableActiveSeasons: [],
        application: null,
        salaryRangeItems: [],
        attachmentsToRemove: [],
        selectedApplicationId: null,
        workersCouncilMembers: [],
        hasLoadedAllWorkersCouncilMembers: false,
        delegateUserGid: null,
        delegateUser: null,
        employeeGid: null,
        employeeUser: null,
        personalDataUsageAcceptationDate: null
    }
})
@Injectable()
export class ApplicationProcessState {
    constructor(
        private seasonService: SeasonService,
        private applicationService: ApplicationService,
        private attachmentsService: AttachmentService,
        private userService: UserService,
        private store: Store
    ) {}

    @Selector()
    static getStepper(state: ApplicationProcessStateModel): ApplicationProcessStepper {
        return state.stepper;
    }

    @Selector()
    static getForm(state: ApplicationProcessStateModel): ApplicationProcessForm | undefined {
        return state.applicationProcessForm.model;
    }

    @Selector()
    static getAvailableActiveSeasons(state: ApplicationProcessStateModel): Season[] {
        return state.availableActiveSeasons;
    }

    @Selector()
    static getSalaryRangeItems(state: ApplicationProcessStateModel): SalaryRangeItem[] {
        return state.salaryRangeItems;
    }

    @Selector()
    static getAllAttachments(state: ApplicationProcessStateModel): UserAttachment[] {
        return state.applicationProcessForm.model?.invoices.attachments || [];
    }

    @Selector()
    static getApplicationInvoices(state: ApplicationProcessStateModel): UserAttachment[] {
        return (
            state.applicationProcessForm.model?.invoices.attachments?.filter(
                attachment => attachment.attachmentCategory === AttachmentCategory.INVOICE
            ) || []
        );
    }

    @Selector()
    static getApplicationConsents(state: ApplicationProcessStateModel): UserAttachment[] {
        return (
            state.applicationProcessForm.model?.invoices.attachments?.filter(
                attachment => attachment.attachmentCategory === AttachmentCategory.CONSENT
            ) || []
        );
    }

    @Selector()
    static getSelectedApplicationId(state: ApplicationProcessStateModel): string | null {
        return state.selectedApplicationId;
    }

    @Selector()
    static getWorkersCouncilMembers(state: ApplicationProcessStateModel): User[] {
        return state.workersCouncilMembers;
    }

    @Selector()
    static getWorkersCouncilMembersOptions(state: ApplicationProcessStateModel): { label: string; value: User }[] {
        return state.workersCouncilMembers
            .map(user => ({
                label: `${user.firstName} ${user.lastName} (${user.gid})`,
                value: user
            }))
            .sort((user1, user2) => user1.label.localeCompare(user2.label));
    }

    @Selector()
    static getDelegatedUser(state: ApplicationProcessStateModel): GbrUser | null {
        return state.delegateUser;
    }

    @Selector()
    static getDelegatedUserGid(state: ApplicationProcessStateModel): string | null {
        return state.delegateUserGid;
    }

    @Selector()
    static getEmployeeUser(state: ApplicationProcessStateModel): GbrUser | null {
        return state.employeeUser;
    }

    @Selector()
    static getApplication(state: ApplicationProcessStateModel): UserApplication | null {
        return state.application;
    }

    @Selector()
    static getPersonalDataUsageAcceptationDate(state: ApplicationProcessStateModel): Date | null {
        return state.personalDataUsageAcceptationDate;
    }

    @Action(IncrementStepper)
    IncrementStepper(context: StateContext<ApplicationProcessStateModel>) {
        const currentStepperValue = context.getState().stepper;
        context.patchState({
            stepper: {
                currentStep: currentStepperValue.currentStep + 1,
                active: currentStepperValue.active + 1,
                selected: currentStepperValue.selected + 1
            }
        });
    }

    @Action(DecrementStepper)
    decrementStepper(context: StateContext<ApplicationProcessStateModel>) {
        const currentStepperValue = context.getState().stepper;
        context.patchState({
            stepper: {
                currentStep: currentStepperValue.currentStep - 1,
                active: currentStepperValue.active - 1,
                selected: currentStepperValue.selected - 1
            }
        });
    }
    @Action(ResetStepper)
    resetStepper(context: StateContext<ApplicationProcessStateModel>) {
        context.patchState({
            stepper: {
                currentStep: 0,
                active: 0,
                selected: 0
            }
        });
    }

    @Action(SetStepper)
    setStepper(context: StateContext<ApplicationProcessStateModel>, action: SetStepper) {
        context.patchState({
            stepper: {
                currentStep: action.step,
                active: action.step,
                selected: action.step
            }
        });
    }

    @Action(ResetApplicationForm)
    resetForm(context: StateContext<ApplicationProcessStateModel>) {
        context.patchState({
            selectedApplicationId: null,
            application: null,
            employeeGid: null,
            delegateUserGid: null,
            employeeUser: null,
            delegateUser: null,
            attachmentsToRemove: [],
            personalDataUsageAcceptationDate: null,
            applicationProcessForm: {
                model: {
                    personalDetails: {
                        privateEmailAddress: '',
                        isSingleParent: null,
                        isOnParentalLeave: null,
                        isCaregiver: null,
                        isShiftWorker: null,
                        hasSevereDisability: null,
                        isPartTimeOrPartialRetirement: null
                    },
                    travelDates: {
                        startDate: null,
                        endDate: null
                    },
                    incomeRange: {
                        monthlyIncomeRange: ''
                    },
                    spouse: {
                        hasTraveledWithSpouse: null,
                        spouseName: '',
                        hasSpouseSevereDisability: null,
                        isSpouseSiemensEmployee: null,
                        spouseGid: ''
                    },
                    children: {
                        hasTraveledWithChild: null,
                        children: []
                    },
                    invoices: {
                        attachments: []
                    },
                    summary: {
                        dataCorrectnessConfirmation: false,
                        relaxationPurposesConsent: false
                    }
                }
            }
        });
    }

    @Action(GetAvailableActiveSeasons)
    getAvailableActiveSeasons(context: StateContext<ApplicationProcessStateModel>) {
        const pageSize = 20;
        const activeSeasons: Season[] = [];
        const { employeeGid } = context.getState();
        const today = new Date();

        if (!employeeGid) {
            return throwError(() => new Error('Employee GID is not given.'));
        }

        return this.seasonService.getActiveSeasonsWithoutSubmittedApplicationsByUser(0, pageSize, employeeGid).pipe(
            expand((result: Page<Season>) =>
                this.seasonService.getActiveSeasonsWithoutSubmittedApplicationsByUser(
                    result.metadata.currentPage + 1,
                    pageSize,
                    employeeGid
                )
            ),
            takeWhile(result => result.metadata.hasNextPage, true),
            map(result => result.items.filter(season => new Date(season.startDate!) <= today)),
            scan((acc, filteredSeasons) => acc.concat(filteredSeasons), activeSeasons),
            tap(result => {
                context.patchState({
                    availableActiveSeasons: result
                });
            })
        );
    }

    @Action(GetSalaryRanges)
    getSalaryRanges(context: StateContext<ApplicationProcessStateModel>, { payload }: GetSalaryRanges) {
        const seasonId = payload?.seasonId ?? context.getState().application?.seasonId;
        if (!seasonId) {
            context.patchState({
                salaryRangeItems: []
            });
            return;
        }
        return this.seasonService
            .getSalaryRangeItems(seasonId)
            .pipe(tap(result => context.patchState({ salaryRangeItems: result })));
    }

    @Action(AddAttachment)
    addAttachment(context: StateContext<ApplicationProcessStateModel>, action: AddAttachment) {
        const attachments = context.getState().applicationProcessForm.model?.invoices.attachments || [];
        attachments.push(action.attachment);

        context.setState(
            patch({
                applicationProcessForm: patch({
                    model: patch({
                        invoices: {
                            attachments
                        }
                    })
                })
            })
        );
    }

    @Action(RemoveAttachment)
    removeAttachment(context: StateContext<ApplicationProcessStateModel>, action: RemoveAttachment) {
        const attachments = context.getState().applicationProcessForm.model?.invoices.attachments || [];

        const attachment = attachments[action.index];

        if (!attachment.file) {
            context.patchState({
                attachmentsToRemove: [...context.getState().attachmentsToRemove, attachment]
            });
        }

        attachments.splice(action.index, 1);

        context.setState(
            patch({
                applicationProcessForm: patch({
                    model: patch({
                        invoices: {
                            attachments
                        }
                    })
                })
            })
        );
    }

    @Action(SaveApplication)
    saveApplication(context: StateContext<ApplicationProcessStateModel>): Observable<void> {
        const state = context.getState();

        if (!state.selectedApplicationId) {
            throw new Error('Application ID not found');
        }

        const { seasonId, user } = ApplicationProcessCreateOperations.checkIfFormSaveable(
            state.applicationProcessForm.model!,
            state.availableActiveSeasons,
            this.store.selectSnapshot(UserState.user)
        );

        const employeeGid = state.employeeGid;
        const delegateGid = state.delegateUserGid;

        return this.applicationService
            .update(
                state.selectedApplicationId,
                ApplicationProcessCreateOperations.mapFormToCreateApplication(
                    state.applicationProcessForm.model!,
                    seasonId,
                    employeeGid ?? user.gid,
                    delegateGid ?? undefined,
                    state.personalDataUsageAcceptationDate ?? undefined
                )
            )
            .pipe(
                tap(application => {
                    context.patchState({
                        selectedApplicationId: application.id
                    });
                }),
                concatMap(() => context.dispatch(new SyncAttachments()))
            );
    }

    @Action(CreateApplication)
    createApplication(context: StateContext<ApplicationProcessStateModel>): Observable<void> {
        const state = context.getState();

        const { seasonId, user } = ApplicationProcessCreateOperations.checkIfFormSaveable(
            state.applicationProcessForm.model!,
            state.availableActiveSeasons,
            this.store.selectSnapshot(UserState.user)
        );

        const employeeGid = state.employeeGid;
        const delegateGid = state.delegateUserGid;

        return this.applicationService
            .create(
                ApplicationProcessCreateOperations.mapFormToCreateApplication(
                    state.applicationProcessForm.model!,
                    seasonId,
                    employeeGid ?? user.gid,
                    delegateGid ?? undefined,
                    state.personalDataUsageAcceptationDate ?? undefined
                )
            )
            .pipe(
                tap(application => {
                    context.patchState({
                        selectedApplicationId: application.id
                    });
                }),
                concatMap(() => context.dispatch(new SyncAttachments()))
            );
    }

    @Action(SubmitApplicationForm)
    submitApplicationForm(context: StateContext<ApplicationProcessStateModel>) {
        const applicationId = context.getState().selectedApplicationId;

        return context
            .dispatch(applicationId ? new SaveApplication() : new CreateApplication())
            .pipe(switchMap(() => this.applicationService.submit(context.getState().selectedApplicationId!)));
    }

    @Action(LoadApplication)
    loadApplication(context: StateContext<ApplicationProcessStateModel>, action: LoadApplication) {
        return this.applicationService.get(action.id).pipe(
            tap(application => {
                context.dispatch(
                    new UpdateFormValue({
                        value: ApplicationProcessCreateOperations.mapApplicationToForm(application),
                        path: 'application.applicationProcessForm'
                    })
                );

                context.patchState({
                    application,
                    selectedApplicationId: application.id,
                    delegateUserGid: application.delegateGid ?? null,
                    employeeGid: application.employeeGid,
                    employeeUser: application.employee as GbrUser,
                    personalDataUsageAcceptationDate: application.personalDataUsageAcceptationDate
                });
            }),
            catchError(error => {
                return throwError(
                    () => new Error(`Failed to load application with ID: ${action.id}. Error: ${error.message}`)
                );
            })
        );
    }

    @Action(LoadDelegateUser)
    loadDelegatedUser(context: StateContext<ApplicationProcessStateModel>) {
        const employeeGid = context.getState().employeeGid;
        const delegateUserGid = context.getState().delegateUserGid;

        if (!employeeGid || !delegateUserGid) {
            return EMPTY;
        }

        return this.userService.getUserFromScd({ gid: delegateUserGid }).pipe(
            filter(user => !!user),
            tap(delegateUser => {
                context.patchState({
                    delegateUser: {
                        firstName: delegateUser!.firstName,
                        lastName: delegateUser!.surName,
                        gid: delegateUser!.gid,
                        orgCode: (delegateUser as any)['custom:orgCode']
                    } as unknown as GbrUser
                });
            })
        );
    }

    @Action(SyncAttachments)
    syncAttachments(context: StateContext<ApplicationProcessStateModel>) {
        const attachmentsToRemove = context.getState().attachmentsToRemove;
        const attachments = context.getState().applicationProcessForm.model?.invoices.attachments || [];
        const attachmentsToUpload = attachments.filter(attachment => attachment.file);

        return concat(
            attachmentsToRemove.length > 0
                ? from(attachmentsToRemove).pipe(
                      filter(attachment => attachment.attachmentCategory !== AttachmentCategory.CONSENT),
                      concatMap(attachment =>
                          this.attachmentsService.deleteAttachment(
                              context.getState().selectedApplicationId!,
                              attachment
                          )
                      )
                  )
                : of(null),
            attachmentsToUpload.length > 0
                ? from(attachmentsToUpload).pipe(
                      concatMap(attachment =>
                          this.attachmentsService.uploadAttachment(
                              context.getState().selectedApplicationId!,
                              attachment.file!,
                              attachment.attachmentCategory
                          )
                      )
                  )
                : of(null)
        );
    }

    @Action(LoadAttachments)
    loadAttachments(context: StateContext<ApplicationProcessStateModel>, action: LoadAttachments) {
        return this.attachmentsService.getAttachments(action.applicationId).pipe(
            tap(attachments => {
                context.setState(
                    patch({
                        applicationProcessForm: patch({
                            model: patch({
                                invoices: {
                                    attachments
                                }
                            })
                        })
                    })
                );
            })
        );
    }

    @Action(DeleteAllAttachments)
    deleteAttachments(context: StateContext<ApplicationProcessStateModel>, action: DeleteAllAttachments) {
        const attachments = context.getState().applicationProcessForm.model?.invoices.attachments || [];
        const applicationStatus = context.getState().application?.status;

        if (attachments.length === 0) {
            return of(null);
        }

        if (
            action.error &&
            (applicationStatus === ApplicationStatus.DRAFT || applicationStatus === ApplicationStatus.CANCELED)
        ) {
            return of(null);
        }

        return this.attachmentsService
            .deleteAllAttachments(context.getState().selectedApplicationId!, attachments)
            .pipe(
                tap(() => {
                    context.setState(
                        patch({
                            applicationProcessForm: patch({
                                model: patch({
                                    invoices: {
                                        attachments: []
                                    }
                                })
                            })
                        })
                    );
                })
            );
    }

    @Action(DownloadAttachment)
    downloadAttachment(context: StateContext<ApplicationProcessStateModel>, action: DownloadAttachment) {
        if (action.attachment.file) {
            AttachmentOperations.downloadFile(action.attachment.file);
            return of(null);
        }
        return this.attachmentsService.downloadAttachment(context.getState().selectedApplicationId!, action.attachment);
    }

    @Action(LoadWorkersCouncilMembers)
    loadDelegatableUsers(
        context: StateContext<ApplicationProcessStateModel>,
        { search, page }: LoadWorkersCouncilMembers
    ) {
        if (context.getState().hasLoadedAllWorkersCouncilMembers && search === '') {
            return;
        }

        const user = this.store.selectSnapshot(UserState.user);
        const groups = user?.delegateForGroups?.map(group => group.id) || [];

        return this.userService.getWorkerCouncilMembers(groups, page, search).pipe(
            tap(({ items, metadata }) =>
                context.patchState({
                    hasLoadedAllWorkersCouncilMembers: search === '' ? !metadata.hasNextPage : false,
                    workersCouncilMembers: page === 0 ? items : [...context.getState().workersCouncilMembers, ...items]
                })
            )
        );
    }

    @Action(SetDelegatedUser)
    setDelegateUser(context: StateContext<ApplicationProcessStateModel>, action: SetDelegatedUser) {
        const user = this.store.selectSnapshot(UserState.user);

        context.patchState({
            employeeUser: action.user,
            employeeGid: action.user.gid,
            delegateUser: user,
            delegateUserGid: user?.gid
        });
    }

    @Action(SetApplicantUser)
    setApplicantUser(context: StateContext<ApplicationProcessStateModel>) {
        const user = this.store.selectSnapshot(UserState.user);
        context.patchState({
            employeeUser: user,
            employeeGid: user?.gid,
            delegateUser: null,
            delegateUserGid: null
        });
    }

    @Action(SetPersonalDataUsageAcceptationDate)
    setPersonalDataUsageAcceptationDate(context: StateContext<ApplicationProcessStateModel>) {
        context.patchState({
            personalDataUsageAcceptationDate: new Date()
        });
    }
}
