import { HttpClient } from '@angular/common/http';
import { EMPTY, forkJoin, map, Observable, switchMap, tap } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpHelperService } from './http-helper.service';
import { UserAttachment } from '../interfaces/Attachments';
import { environment } from '../../../environments/environment';
import { ListAttachmentsResponse } from '../../dashboard/application-process/models/attachments.model';
import { AttachmentOperations } from '../operations/attachment.operations';

@Injectable({
    providedIn: 'root'
})
export class AttachmentService {
    protected baseUrl = environment.apiUrl;

    constructor(protected httpClient: HttpClient, protected httpHelperService: HttpHelperService) {}

    public getAttachments(applicationId: string, gid: string): Observable<UserAttachment[]> {
        return this.httpClient
            .get<ListAttachmentsResponse[]>(`${this.baseUrl}/user-attachments/${gid}/${applicationId}`)
            .pipe(
                map(attachments => attachments.map(attachment => ({ name: attachment.Key.split('/')[1] }))),
                this.httpHelperService.handleError(`Could not fetch attachments`)
            );
    }

    public uploadAttachment(applicationId: string, gid: string, file: File): Observable<void> {
        return this.httpClient
            .get<{ signedUrl: string }>(
                `${this.baseUrl}/user-attachments/${gid}/${encodeURIComponent(
                    `${applicationId}/${file.name}`
                )}/links/upload?contentType=${file.type}`
            )
            .pipe(
                this.httpHelperService.handleError(`Could not get signed URL for upload`),
                switchMap(({ signedUrl }) =>
                    this.httpClient
                        .put<void>(signedUrl, file)
                        .pipe(this.httpHelperService.handleError(`Could not upload file`))
                )
            );
    }

    public downloadAttachment(applicationId: string, gid: string, fileName: string): Observable<{ signedUrl: string }> {
        return this.httpClient
            .get<{ signedUrl: string }>(
                `${this.baseUrl}/user-attachments/${gid}/${encodeURIComponent(
                    `${applicationId}/${fileName}`
                )}/links/download`
            )
            .pipe(
                this.httpHelperService.handleError(`Could not get signed URL for download`),
                tap(({ signedUrl }) => AttachmentOperations.downloadFile(signedUrl, fileName))
            );
    }

    public deleteAttachment(applicationId: string, gid: string, filePathAndName: string): Observable<void> {
        return this.httpClient
            .delete<void>(
                `${this.baseUrl}/user-attachments/${gid}/${encodeURIComponent(`${applicationId}/${filePathAndName}`)}`
            )
            .pipe(this.httpHelperService.handleError(`Could not delete attachment`));
    }

    public deleteAllAttachments(applicationId: string, gid: string, fileNames: string[]): Observable<void[]> {
        const observables = fileNames.map(fileName => this.deleteAttachment(applicationId, gid, fileName));

        return forkJoin(observables).pipe(this.httpHelperService.handleError(`Could not delete attachments`));
    }
}
