import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import {
    CancelApplication,
    DeleteApplication,
    GetApplications,
    GetSubmittedApplications,
    LazyLoadApplication
} from './applications.action';
import { ApplicationService } from '../services/applications.service';
import { ApplicationItem } from '../interfaces/application-item';
import { ApplicationType } from '../interfaces/application-type.enum';
import { produce } from 'immer';
import { catchError, expand, filter, Observable, of, OperatorFunction, scan, takeWhile, tap } from 'rxjs';
import { patch, removeItem } from '@ngxs/store/operators';
import { UserApplication, Metadata, Page, Application } from 'interfaces/app';
import { ApplicationStatus } from 'interfaces/enums';

export interface ApplicationsStateModel {
    applications: UserApplication[];
    submittedApplications: UserApplication[];
    isLoading: boolean;
    metadata: Metadata | undefined;
}

const PAGE_SIZE = 20;

@State<ApplicationsStateModel>({
    name: 'applicationsState',
    defaults: {
        applications: [],
        submittedApplications: [],
        isLoading: true,
        metadata: undefined
    }
})
@Injectable()
export class ApplicationsState {
    constructor(private applicationsService: ApplicationService, private store: Store) {}

    @Selector()
    static getApplications(state: ApplicationsStateModel): ApplicationItem[] {
        return state.applications
            .map(a => ({ ...a, type: _getApplicationType(a) }))
            .sort((a, b) => new Date(b.travelStartDate).getTime() - new Date(a.travelStartDate).getTime());
    }

    @Selector()
    static getSeasonGroupedApplications(state: ApplicationsStateModel): Record<string, ApplicationItem[]> {
        return this.getApplications(state).reduce((groups, application) => {
            const key = application.seasonId;
            if (!groups[key]) {
                groups[key] = [];
            }
            groups[key].push(application);
            return groups;
        }, {} as Record<string, ApplicationItem[]>);
    }

    @Selector()
    static isLoading(state: ApplicationsStateModel): boolean {
        return state.isLoading;
    }

    @Selector()
    static isInitalLoading(state: ApplicationsStateModel): boolean {
        return state.isLoading && state.applications.length <= 0;
    }

    @Selector()
    static allApplicationsLoaded(state: ApplicationsStateModel): boolean {
        return !state.metadata?.hasNextPage;
    }

    @Selector()
    static getSubmittedApplications(state: ApplicationsStateModel): ApplicationItem[] {
        return state.submittedApplications.map(a => ({ ...a, type: _getApplicationType(a) }));
    }

    @Action(GetApplications)
    public loadApplications(ctx: StateContext<ApplicationsStateModel>): Observable<Page<UserApplication> | undefined> {
        ctx.patchState({ isLoading: true });
        return this.applicationsService.getApplications(0, PAGE_SIZE, true).pipe(
            catchError(() => {
                ctx.patchState({ isLoading: false });
                return of(void 0);
            }),
            filter(result => !!result) as OperatorFunction<Page<UserApplication> | undefined, Page<UserApplication>>,
            tap(result =>
                ctx.setState(
                    produce(ctx.getState(), state => {
                        state.applications = result.items;
                        state.metadata = result.metadata;
                        state.isLoading = false;
                    })
                )
            )
        );
    }

    @Action(GetSubmittedApplications)
    public loadSubmittedApplications(ctx: StateContext<ApplicationsStateModel>) {
        const pageSize = 20;
        const submittedApplications: UserApplication[] = [];
        return this.applicationsService.getSubmittedApplications(0, pageSize).pipe(
            expand((result: Page<Application>) =>
                this.applicationsService.getSubmittedApplications(result.metadata.currentPage + 1, pageSize)
            ),
            takeWhile(result => result.metadata.hasNextPage, true),
            scan((acc, result) => acc.concat(result.items), submittedApplications),
            tap(result =>
                ctx.setState(
                    produce(ctx.getState(), state => {
                        state.submittedApplications = result;
                    })
                )
            )
        );
    }

    @Action(LazyLoadApplication)
    lazyLoadApplication(ctx: StateContext<ApplicationsStateModel>): Observable<Page<UserApplication> | undefined> {
        ctx.patchState({ isLoading: true });
        const page = Math.round(ctx.getState().applications.length / PAGE_SIZE);
        return this.applicationsService.getApplications(page, PAGE_SIZE, true).pipe(
            catchError(() => {
                ctx.patchState({ isLoading: false });
                return of(void 0);
            }),
            filter(result => !!result) as OperatorFunction<Page<UserApplication> | undefined, Page<UserApplication>>,
            tap(result =>
                ctx.setState(
                    produce(ctx.getState(), state => {
                        state.applications = result.items;
                        state.metadata = result.metadata;
                        state.isLoading = false;
                    })
                )
            )
        );
    }

    @Action(DeleteApplication)
    deleteApplication(
        ctx: StateContext<ApplicationsStateModel>,
        { applicationId }: DeleteApplication
    ): Observable<void> {
        ctx.patchState({ isLoading: true });
        return this.applicationsService.deleteApplication(applicationId).pipe(
            catchError(() => {
                ctx.patchState({ isLoading: false });
                return of(void 0);
            }),
            tap(() =>
                ctx.setState(
                    patch({
                        applications: removeItem(applications => applications.id === applicationId),
                        isLoading: false
                    })
                )
            )
        );
    }

    @Action(CancelApplication)
    cancelApplication(
        ctx: StateContext<ApplicationsStateModel>,
        { applicationId }: CancelApplication
    ): Observable<void> {
        ctx.patchState({ isLoading: true });
        return this.applicationsService.cancelApplication(applicationId).pipe(
            catchError(() => {
                ctx.patchState({ isLoading: false });
                return of(void 0);
            }),
            tap(() =>
                ctx.setState(
                    patch({
                        applications: applications =>
                            applications.map(application =>
                                application.id === applicationId
                                    ? { ...application, status: ApplicationStatus.CANCELED }
                                    : application
                            ),
                        isLoading: false
                    })
                )
            )
        );
    }
}

function _getApplicationType(application: UserApplication): ApplicationType {
    const travelEndDate = new Date(application.travelEndDate);
    const travelStartDate = new Date(application.travelStartDate);

    const diffInMilliseconds = Math.abs(travelEndDate.getTime() - travelStartDate.getTime());
    const diffInDays = diffInMilliseconds / (1000 * 60 * 60 * 24);
    return diffInDays > 13 ? ApplicationType.LONG : ApplicationType.SHORT;
}
