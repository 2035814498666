import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import {
    ApplicationProcessForm,
    ApplicationProcessFormGroup,
    ChildFormGroup,
    ChildItemForm,
    ChildItemFormGroup,
    IncomeRangeFormGroup,
    InvoicesFormGroup,
    PersonalDetailsFormGroup,
    SpouseFormGroup,
    SummaryFormGroup,
    TravelDatesFormGroup
} from '../models/application-process-interfaces';
import { CustomValidators } from '../validators/custom-validators';
import { UserAttachment } from '../../../shared/interfaces/Attachments';
import { Child, UserApplication, Season, CreateApplication, CreateApplicationChild } from 'interfaces/app';
import { GbrUser } from 'interfaces/auth';

const EMAIL_REGEX = '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-.]+$';

export class ApplicationProcessCreateOperations {
    public static initializeForm(): FormGroup<ApplicationProcessFormGroup> {
        return new FormGroup<ApplicationProcessFormGroup>({
            personalDetails: new FormGroup<PersonalDetailsFormGroup>({
                privateEmailAddress: new FormControl<string | null>('', [Validators.pattern(EMAIL_REGEX)]),
                isSingleParent: new FormControl<boolean | null>(null, Validators.required),
                isOnParentalLeave: new FormControl<boolean | null>(null, Validators.required),
                isCaregiver: new FormControl<boolean | null>(null, Validators.required),
                isShiftWorker: new FormControl<boolean | null>(null, Validators.required),
                hasSevereDisability: new FormControl<boolean | null>(null, Validators.required),
                isPartTimeOrPartialRetirement: new FormControl<boolean | null>(null, Validators.required)
            }),
            travelDates: new FormGroup<TravelDatesFormGroup>(
                {
                    startDate: new FormControl<Date | null>(null, Validators.required),
                    endDate: new FormControl<Date | null>(null, Validators.required)
                },
                CustomValidators.dates([])
            ),
            incomeRange: new FormGroup<IncomeRangeFormGroup>({
                monthlyIncomeRange: new FormControl<string | null>(null, Validators.required)
            }),
            spouse: new FormGroup<SpouseFormGroup>(
                {
                    hasTraveledWithSpouse: new FormControl<boolean | null>(null, Validators.required),
                    spouseName: new FormControl<string | null>(null),
                    hasSpouseSevereDisability: new FormControl<boolean | null>(null),
                    isSpouseSiemensEmployee: new FormControl<boolean | null>(null),
                    spouseGid: new FormControl<string | null>(null, CustomValidators.gid())
                },
                CustomValidators.spouse()
            ),
            children: new FormGroup<ChildFormGroup>(
                {
                    hasTraveledWithChild: new FormControl<boolean | null>(null, Validators.required),
                    children: new FormArray<FormGroup<ChildItemFormGroup>>([])
                },
                CustomValidators.children()
            ),
            invoices: new FormGroup<InvoicesFormGroup>({
                attachments: new FormControl<UserAttachment[]>([], CustomValidators.minArrayLength(1))
            }),
            summary: new FormGroup<SummaryFormGroup>({
                dataCorrectnessConfirmation: new FormControl<boolean | null>(false, Validators.required),
                relaxationPurposesConsent: new FormControl<boolean | null>(false, Validators.required)
            })
        });
    }

    public static mapFormToCreateApplication(
        form: ApplicationProcessForm,
        seasonId: string,
        employeeGid: string,
        delegateGid?: string,
        personalDataUsageAcceptationDate?: Date
    ): CreateApplication {
        const personalDetails = form.personalDetails;

        const children = form.children.children.map(child => ({
            birthDate: child.birthdate,
            isEligible: child.hasChildAllowence,
            isDisabled: child.hasChildSevereDisability
        })) as CreateApplicationChild[];

        return {
            employeeGid,
            seasonId,
            delegateGid,
            isSingleParent: personalDetails.isSingleParent ?? undefined,
            isCaregiver: personalDetails.isCaregiver ?? undefined,
            isDisabled: personalDetails.hasSevereDisability ?? undefined,
            isOnParentalLeave: personalDetails.isOnParentalLeave ?? undefined,
            isPartTime: personalDetails.isPartTimeOrPartialRetirement ?? undefined,
            isShiftWorker: personalDetails.isShiftWorker ?? undefined,
            spouse: form.spouse.spouseName
                ? {
                      firstName: form.spouse.spouseName,
                      lastName: form.spouse.spouseName,
                      gid: form.spouse.spouseGid,
                      isSiemensEmployee: form.spouse.isSpouseSiemensEmployee ?? undefined,
                      isDisabled: form.spouse.hasSpouseSevereDisability ?? undefined
                  }
                : undefined,
            children,
            travelStartDate: form.travelDates.startDate! ?? new Date(),
            travelEndDate: form.travelDates.endDate! ?? new Date(),
            dataCorrectnessConfirmationDate: form.summary.dataCorrectnessConfirmation ? new Date() : null,
            relaxationPurposesConsentDate: form.summary.relaxationPurposesConsent ? new Date() : null,
            emailPrivate: personalDetails.privateEmailAddress || undefined,
            salaryRangeId: form.incomeRange.monthlyIncomeRange || undefined,
            personalDataUsageAcceptationDate
        };
    }

    public static mapChildToChildItemForm(child: Child): ChildItemForm {
        return {
            birthdate: child.birthDate ?? null,
            hasChildAllowence: child.isEligible ?? null,
            hasChildSevereDisability: child.isDisabled ?? null
        };
    }

    public static mapApplicationToForm(application: UserApplication): ApplicationProcessForm {
        return {
            personalDetails: {
                privateEmailAddress: application.emailPrivate ?? '',
                isSingleParent: application.isSingleParent ?? null,
                isOnParentalLeave: application.isOnParentalLeave ?? null,
                isCaregiver: application.isCaregiver ?? null,
                isShiftWorker: application.isShiftWorker ?? null,
                hasSevereDisability: application.isDisabled ?? null,
                isPartTimeOrPartialRetirement: application.isPartTime ?? null
            },
            travelDates: {
                startDate: new Date(application.travelStartDate),
                endDate: new Date(application.travelEndDate)
            },
            incomeRange: {
                monthlyIncomeRange: application.salaryRange?.id ?? null
            },
            spouse: {
                hasTraveledWithSpouse: !!application.spouse,
                spouseName: application.spouse?.firstName ?? '',
                hasSpouseSevereDisability: application.spouse?.isDisabled ?? null,
                isSpouseSiemensEmployee: application.spouse?.isSiemensEmployee ?? null,
                spouseGid: application.spouse?.gid ?? ''
            },
            children: {
                hasTraveledWithChild: !!application.children?.length,
                children: application.children?.map(this.mapChildToChildItemForm) ?? []
            },
            invoices: {
                attachments: []
            },
            summary: {
                dataCorrectnessConfirmation: false,
                relaxationPurposesConsent: false
            }
        };
    }

    public static checkIfFormSaveable(
        form: ApplicationProcessForm,
        activeSeasons: Season[],
        user?: GbrUser
    ): { seasonId: string; user: GbrUser } {
        const travelDates = form?.travelDates;

        if (!user) {
            throw new Error('User not found');
        }

        if (!travelDates?.startDate || !travelDates.endDate) {
            throw new Error('Travel dates not found');
        }

        const selectedSeason = activeSeasons.find(season => {
            if (!season.startDate || !season.endDate) {
                return false;
            }

            return (
                travelDates.endDate! >= new Date(season.startDate) && travelDates.endDate! <= new Date(season.endDate)
            );
        });

        if (!selectedSeason) {
            throw new Error('Selected season not found');
        }

        return {
            seasonId: selectedSeason.id,
            user
        };
    }

    public static createChildFormGroup(data?: ChildItemForm): FormGroup<ChildItemFormGroup> {
        return new FormGroup<ChildItemFormGroup>({
            birthdate: new FormControl<Date | null>(data?.birthdate ?? null, [
                Validators.required,
                CustomValidators.age(25),
                CustomValidators.pastDate()
            ]),
            hasChildAllowence: new FormControl<boolean | null>(data?.hasChildAllowence ?? null, [Validators.required]),
            hasChildSevereDisability: new FormControl<boolean | null>(data?.hasChildSevereDisability ?? null, [
                Validators.required
            ])
        });
    }
}
