import { Component, Input, OnDestroy, OnInit, Signal } from '@angular/core';
import { ThemeService } from '../../services/theme.service';
import { Subject } from 'rxjs';
import { LanguageCode } from 'interfaces/enums';
import { select, Store } from '@ngxs/store';
import { UserState } from '../../../auth/state/user.state';
import { SetUserLanguage } from '../../../auth/state/user.action';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
    protected selectedLangSignal: Signal<LanguageCode> = select(UserState.userLanguage);
    @Input() wasScrolled: boolean;
    protected isSidebarOpen = false;
    protected langCode = LanguageCode;
    private destroy$ = new Subject<void>();

    constructor(private store: Store, private themeService: ThemeService) {}

    ngOnInit(): void {
        this.setTheme('dark');
    }

    public changeLang(language: LanguageCode): void {
        this.store.dispatch(new SetUserLanguage({ language }));
    }

    private setTheme(mode: 'dark' | 'light') {
        this.themeService.setTheme(mode);
        localStorage.setItem('theme', JSON.stringify(mode));
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
