<div class="header-wrapper justify-center flex flex-col" [ngClass]="wasScrolled ? 'bg-[#00183B] transition duration-200 ease-in-out' : 'bg-transparent duration-200 ease-in-out'">
    <!-- top part -->
    <div>
        <div class="nav-part start cursor-pointer" [routerLink]="['/']">
            <div class="logo"><img [src]="'assets/images/sag-logo/sie-logo-white-rgb.svg'" alt="Siemens logo" /></div>
            <div class="next-logo"><img [src]="'assets/images/next-siemens-logo.svg'" alt="Next Siemens logo" /></div>
            <div class="app-name">GBR EMA Cloud</div>
        </div>
        <div class="nav-part end hide-mobile">
            <button class="nav-icon" [matMenuTriggerFor]="langMenu"><span class="icon-region"></span></button>
            <mat-menu #langMenu="matMenu">
                <button (click)="changeLang('en')" mat-menu-item>English</button>
                <button (click)="changeLang('de')" mat-menu-item>Deutsch</button>
            </mat-menu>

            <app-user class="hide-mobile"></app-user>
        </div>
    </div>
</div>
