import { Component, HostListener, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    title = 'frontend-sag';
    protected wasScrolled = false;

    constructor(private translate: TranslateService, private router: Router) {}

    ngOnInit(): void {
        this.routeToRedirect();
        this.translate.setDefaultLang('de');
        this.translate.use('de');
    }

    private routeToRedirect(): void {
        const url = window.localStorage.getItem('redirectUrl');
        if (url) {
            void this.router.navigateByUrl(url);
            window.localStorage.removeItem('redirectUrl');
        }
    }

    // determine if user scrolled to decide if header should have background or be transparent
    @HostListener('window:scroll', [])
    onWindowScroll(): void {
        if (document.querySelector('.cdk-global-scrollblock')) {
            // When displaying dialogs, stop checking for scroll. As the scrolledDistance is always equal to 0
            return;
        }

        const scrolledDistance = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;
        this.wasScrolled = scrolledDistance > 48;
    }
}
