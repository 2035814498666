<div class="footer-wrapper bg-transparent sticky bottom-0">
    <div class="footer">
        <div>© Siemens AG 2024</div>
        <a class="footer-link" [href]="'https://www.siemens.com/global/en/general/legal.html'">Corporate Information</a>
        <a class="footer-link" [href]="'https://www.siemens.com/global/en/general/privacy-notice.html'">Privacy Notice</a>
        <a class="footer-link" [href]="'https://www.siemens.com/global/en/general/cookie-notice.html'">Cookie Notice</a>
        <a class="footer-link" [href]="'https://www.siemens.com/global/en/general/terms-of-use.html'">Terms of Use</a>
        <a class="footer-link" [href]="'https://www.siemens.com/global/en/general/digital-id.html'">Digital ID</a>
    </div>
</div>
